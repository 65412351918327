import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import Translations from '../../../../assets/locale/en/content.json';
import { TngButton, TngDivider, TngGrid, TngTypography } from '../../../../components/common';
import { FieldConfig } from '../modals/FieldConfig';
import { UserDetailsModal } from '../modals/UserDetailsModal';
import { CreateFieldConfigForUserDetails } from '../utils/FieldConfig';

interface UserInfoProps {
  userDetails: UserDetailsModal;
  isEditAccessible: boolean;
}

const UserInfo: React.FC<UserInfoProps> = ({ userDetails, isEditAccessible }) => {
  const navigate = useNavigate();

  // Memoize the derived data
  const fieldConfig: FieldConfig[] = useMemo(
    () => CreateFieldConfigForUserDetails(userDetails),
    [userDetails],
  );

  const handleEnableEdit = () => {
    const url = new URL(window.location.href);
    // Add or update the 'edit' parameter
    url.searchParams.set('edit', 'true');
    // Navigate to the new URL
    navigate(`${location.pathname}?${url.searchParams.toString()}`);
  };

  return (
    <>
      <TngGrid className="user_details_name_section_grid">
        <TngGrid item>
          <TngTypography className="user_name_label">{Translations.ManageUsers.name}</TngTypography>
          <TngTypography className="user_name_heading">
            {`${userDetails.firstName ?? ''} ${userDetails.lastName ?? ''}`}
          </TngTypography>
        </TngGrid>
        {isEditAccessible && (
          <TngGrid item>
            <TngButton className="edit_user_details_btn" onClick={handleEnableEdit}>
              {Translations.ManageUsers.editUserDetails}
            </TngButton>
          </TngGrid>
        )}
      </TngGrid>
      <TngDivider />
      <TngGrid className="user_details_sub_grid">
        {fieldConfig.map(({ name, value }) => (
          <TngGrid item key={name} className="field_value_wrapper">
            <TngTypography className="field_name">{name}</TngTypography>
            <TngTypography className="field_value">
              {value && value.trim() !== '' ? value : Translations.accountSettings.notAvailable}
            </TngTypography>
          </TngGrid>
        ))}
      </TngGrid>
    </>
  );
};

export default UserInfo;
