import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Translations from '../../../assets/locale/en/content.json';
import { RECOVERY_CODE_REQUIRED } from '../../../common/constant';
import useFetchOTP from '../../../common/hooks/useFetchOtp';
import {
  TngButton,
  TngGrid,
  TngInputLabel,
  TngTextField,
  TngTypography,
} from '../../../components/common';

type ForgotPassForm = {
  email: string;
};

type FormError = {
  isError: boolean;
  message: string;
};

const ForgotPassForm: React.FC = () => {
  const [formError, setFormError] = useState<FormError>({ isError: false, message: '' });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ForgotPassForm>();

  const { fetchOTP, loading } = useFetchOTP();

  // Clear form error when user starts typing
  const handleInputChange = () => {
    clearErrors('email');
    setFormError({ isError: false, message: '' });
  };

  const onSubmit: SubmitHandler<ForgotPassForm> = async (data) => {
    clearErrors('email'); // Clear existing errors

    // Validate email format first
    if (!isValidEmail(data.email)) {
      setError('email', { type: 'manual', message: Translations.ForgotPassword.invalidEmailError });
      return;
    }

    try {
      const { status, flowId } = await fetchOTP(data.email);

      if (status === RECOVERY_CODE_REQUIRED) {
        navigate(`/reset-password?flowId=${flowId}&username=${data.email}`);
      }
    } catch (error) {
      setFormError({
        isError: true,
        message: Translations.ForgotPassword.emailNotMatchingErrorAPI,
      });
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <form
      aria-label="Forgot Password"
      className="forgot_password_form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TngGrid className="forgot_password_form_fields">
        <TngGrid item className="form_field">
          <TngInputLabel htmlFor="email" className="forgot_password_input_label">
            {Translations.ForgotPassword.emailAddress}
          </TngInputLabel>
          <TngTextField
            id="email"
            aria-label={Translations.ForgotPassword.emailAddress}
            type="text"
            variant="outlined"
            placeholder={Translations.ForgotPassword.enterEmailAddress}
            fullWidth
            error={!!errors.email}
            helperText={
              errors.email
                ? errors.email.message || Translations.ForgotPassword.invalidEmailError
                : ''
            }
            {...register('email', {
              required: Translations.Login.fieldError, // Field cannot be blank
              validate: {
                validEmail: (value) =>
                  isValidEmail(value) || Translations.ForgotPassword.invalidEmailError, // Validate format
              },
              onChange: handleInputChange, // Add onChange event handler to clear errors
            })}
            className={`forgot_password_input_field ${
              errors.email ? 'forgot_password_input_field_error' : ''
            }`}
            InputProps={{ inputProps: { maxLength: 80 } }}
          />
        </TngGrid>
      </TngGrid>
      {formError.isError && (
        <TngTypography className="forgot_password_form_error">{formError.message}</TngTypography>
      )}
      <TngGrid className="form_button">
        <TngButton
          type="submit"
          variant="contained"
          aria-label={Translations.ForgotPassword.continue}
          className="continue_button"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {Translations.ForgotPassword.continue}
        </TngButton>
      </TngGrid>
    </form>
  );
};

export default ForgotPassForm;
