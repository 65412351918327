import React from 'react';

import { Chip } from '@mui/material';
import { Control, Controller, Path, PathValue } from 'react-hook-form';
import { FieldValues } from 'react-hook-form';

import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';
import { TngGrid, TngInputLabel } from '../common';

interface ChipFieldProps<TFormField extends FieldValues> {
  field: InputFieldConfig;
  control: Control<TFormField>;
}

const ChipField = <TFormField extends FieldValues>({
  field,
  control,
}: ChipFieldProps<TFormField>) => {
  const isMultipleAllowed = field?.allowMultiple !== false;
  return (
    <TngGrid key={field.htmlFor} className="chip_parent_container">
      <TngInputLabel
        htmlFor={field.htmlFor}
        className="form_field_input_label"
        aria-label={field.validationRules.required ? `${field.label} (required)` : field.label}
      >
        {field.validationRules.required ? `${field.label} *` : field.label}
      </TngInputLabel>

      <Controller
        name={field.htmlFor as Path<TFormField>}
        control={control}
        defaultValue={[] as string[] as PathValue<TFormField, Path<TFormField>>}
        render={({ field: controllerField }) => (
          <TngGrid className="chip_child_container">
            {field?.chipOptions?.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.name}
                clickable
                aria-label={`${field.label} ${chip.name} ${controllerField.value?.includes(chip.name) ? 'Selected' : 'Not Selected'}`}
                className={`chip ${controllerField.value?.includes(chip.name) ? 'selected_chip' : ''}`}
                onClick={() => {
                  if (isMultipleAllowed) {
                    // Allow multiple selections
                    const updatedValue = controllerField.value.includes(chip.name)
                      ? controllerField.value.filter((name: string) => name !== chip.name)
                      : [...controllerField.value, chip.name];
                    controllerField.onChange(updatedValue);
                  } else {
                    // Allow only single selection
                    const updatedValue = controllerField.value.includes(chip.name)
                      ? []
                      : [chip.name];
                    controllerField.onChange(updatedValue); // Ensure only one selection
                  }
                }}
              />
            ))}
          </TngGrid>
        )}
      />
    </TngGrid>
  );
};

export default ChipField;
