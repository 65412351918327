import React, { forwardRef } from 'react';

import { Typography } from '@mui/material';

import ProductOffersForm from './components/ProductOffersForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';

interface ProductsYouWantToOfferProps {
  bottomPanelRef: any;
}

const ProductsYouWantToOffer = forwardRef<HTMLDivElement, ProductsYouWantToOfferProps>(
  ({ bottomPanelRef }, ref) => {
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <Typography variant="h4" className="stepper_form_container_header">
            4. {Translations.partnerOnboarding.productsYouWantToOffer}
          </Typography>
          <Typography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </Typography>
        </TngGrid>
        <TngGrid className="stepper_form_helper_text">
          {Translations.partnerOnboarding.productInterestHelperText}
        </TngGrid>
        <ProductOffersForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

ProductsYouWantToOffer.displayName = 'ProductsYouWantToOffer';

export default ProductsYouWantToOffer;
