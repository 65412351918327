import { jwtDecode } from 'jwt-decode';

import { UserTypesEmuns } from '../common/enums/UserTypesEmuns';

interface TokenPayload {
  userType: UserTypesEmuns;
  givenName: string;
  familyName: string;
  userName: string;
}

export const getUserInfoFromToken = (token: string): TokenPayload | null => {
  try {
    const decodedToken = jwtDecode<TokenPayload>(token);
    return decodedToken || null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
