import React, { ReactNode, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { UserTypesEmuns } from '../../common/enums/UserTypesEmuns';
import { useGetCountries } from '../../common/hooks/useGetCountry';
import { useGetState } from '../../common/hooks/useGetState';
import { useGetUserProfile } from '../../common/hooks/useGetUserProfile';
import { useIcons } from '../../common/hooks/useIcons';
import { useUserPermissions } from '../../common/hooks/useUserPermissions';
import { useUserTypes } from '../../common/hooks/useUserType';
import { useMenu } from '../../components/Layout/DashboardLayout/SideMenu/hooks/useMenu';
import { RootState } from '../../store';
import {
  setCountry,
  setIcons,
  setMenu,
  setState,
  setUserTypes,
} from '../../store/master/masterSlice';
import { setLoading, setPermissionsLoader } from '../../store/master/masterSlice';
import { setUserAction, setUserPermissionsAction } from '../../store/user/userSlice';

interface AppServiceProps {
  children: ReactNode;
}

const AppService: React.FC<AppServiceProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const isNonTemporaryUser =
    useSelector((state: RootState) => state.auth.userRole) !== UserTypesEmuns.PTU;
  const isPermissionsLoader = useSelector((state: RootState) => state.master.isPermissionsLoading);
  const isRefreshTokenLoading = useSelector(
    (state: RootState) => state.master.isRefreshTokenLoading,
  );
  const isApiReadyToCall = isAuthenticated && isNonTemporaryUser;

  const { data: userProfile, isLoading: userLoader } = useGetUserProfile(isApiReadyToCall);
  const { data: userPermissions, isLoading: permissionLoader } =
    useUserPermissions(isAuthenticated);
  const { data: countries } = useGetCountries(isAuthenticated);
  const { data: states } = useGetState(isAuthenticated);
  const { data: menuData, isLoading: menuLoader } = useMenu(isAuthenticated);

  const { data: icons, isLoading: iconsLoader } = useIcons(isAuthenticated);
  const { data: userTypes, isLoading: userTypesLoader } = useUserTypes(isApiReadyToCall);

  const setUserProfile = useMemo(
    () => (profile: any) => dispatch(setUserAction(profile)),
    [dispatch],
  );

  const setMenuOptions = useMemo(() => (menu: any) => dispatch(setMenu(menu)), [dispatch]);

  const setUserTypesAction = useMemo(
    () => (userTypes: any) => dispatch(setUserTypes(userTypes)),
    [dispatch],
  );
  const setUserPermissions = useMemo(
    () => (permissions: any) => dispatch(setUserPermissionsAction(permissions)),
    [dispatch],
  );
  const setLoaderState = useMemo(
    () => (state: boolean) => dispatch(setLoading({ isLoading: state })),
    [dispatch],
  );
  const setPermissionsLoaded = useMemo(
    () => (state: boolean) => dispatch(setPermissionsLoader({ isPermissionsLoading: state })),
    [dispatch],
  );

  useEffect(() => {
    const allLoaders = [
      userLoader,
      userTypesLoader,
      permissionLoader,
      isPermissionsLoader,
      isRefreshTokenLoading,
      iconsLoader,
      menuLoader,
    ];

    const allNonTemporaryUserLoaders = [
      isNonTemporaryUser,
      isRefreshTokenLoading,
      iconsLoader,
      permissionLoader,
      isPermissionsLoader,
      menuLoader,
    ];

    if (
      allLoaders.every((loader) => !loader) ||
      allNonTemporaryUserLoaders.every((loader) => !loader)
    ) {
      setLoaderState(false);
    }
  }, [
    userLoader,
    userTypesLoader,
    permissionLoader,
    isPermissionsLoader,
    isRefreshTokenLoading,
    iconsLoader,
    isNonTemporaryUser,
    setLoaderState,
    menuLoader,
  ]);

  useEffect(() => {
    if (userProfile) {
      setUserProfile(userProfile);
    }
  }, [userProfile, setUserProfile]);

  useEffect(() => {
    if (userPermissions) {
      setUserPermissions(userPermissions);
      setPermissionsLoaded(false);
    }
  }, [userPermissions, setUserPermissions, setPermissionsLoaded]);

  useEffect(() => {
    if (countries?.length) {
      dispatch(setCountry({ countries }));
    }
  }, [countries, dispatch]);

  useEffect(() => {
    if (states?.length) {
      dispatch(setState({ states }));
    }
  }, [states, dispatch]);

  useEffect(() => {
    if (userTypes?.length) {
      dispatch(setUserTypesAction({ userTypes }));
    }
  }, [userTypes, dispatch, setUserTypesAction]);

  useEffect(() => {
    if (icons?.length) {
      dispatch(setIcons({ icons: icons }));
    }
  }, [icons, dispatch]);

  useEffect(() => {
    if (menuData?.length) {
      dispatch(setMenuOptions({ menu: menuData }));
    }
  }, [menuData, dispatch]);

  return <>{children}</>;
};

export default AppService;
