import { LookupsEnum } from '../../../common/enums/LookupsEnum';
import { MasterdataEnum } from '../../../common/enums/MasterdataEnum';
import { Lookup, LookupResponse } from '../../../common/interfaces/Lookup';
import {
  QuoteStatus,
  QuoteStatusResponse,
} from '../../../common/interfaces/manageQuotes/QuoteStatus';
import { Master, MasterResponse } from '../../../common/interfaces/Masters';
import {
  ProductList,
  ProductListResponse,
} from '../../../common/interfaces/partnerOnboarding/ProductList';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
  Fetches the quote statuses from the API.
  @returns {Promise<QuoteStatus[]>} - A promise that resolves to an array of quote statuses.
  @throws {Error} - Throws an error if the request fails.
*/

export const fetchQuoteStatus = async (): Promise<QuoteStatus[]> => {
  try {
    const url = getUrl(URLS.QUOTES_STATUS);

    const { data } = await axiosInstance.get<QuoteStatusResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch quote status');
  }
};

/**
 * Fetches lookup data from the API based on the provided lookup type.
 * @param {string} lookupType - The type of lookup to fetch (e.g., 'target-market').
 * @param {string} errorMessage - The error message to throw if the request fails.
 * @returns {Promise<Lookup[]>} - A promise that resolves to an array of lookup data.
 * @throws {Error} - Throws an error if the request fails.
 */
const fetchLookupData = async (lookupType: string, errorMessage: string): Promise<Lookup[]> => {
  try {
    const url = getUrl(URLS.LOOKUPS, { lookupType });
    const { data } = await axiosInstance.get<LookupResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error(errorMessage);
  }
};

/**
 * Fetches the target market lookup from the API.
 * @returns {Promise<Lookup[]>} - A promise that resolves to an array of target market lookup data.
 */
export const fetchTargetMarketLookup = (): Promise<Lookup[]> => {
  return fetchLookupData(LookupsEnum.TARGET_MARKETS, 'Failed to fetch target markets');
};

/**
 * Fetches masters data from the API based on the provided master type.
 * @param {string} masterType - The type of masters to fetch (e.g., 'type-of-companies').
 * @param {string} errorMessage - The error message to throw if the request fails.
 * @returns {Promise<Master[]>} - A promise that resolves to an array of lookup data.
 * @throws {Error} - Throws an error if the request fails.
 */
const fetchMasterData = async (masterType: string, errorMessage: string): Promise<Master[]> => {
  try {
    const url = getUrl(URLS.MASTERS, { masterType });
    const { data } = await axiosInstance.get<MasterResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error(errorMessage);
  }
};

/**
 * Fetches the type-of-companies masterdata from the API.
 * @returns {Promise<Master[]>} - A promise that resolves to an array of type-of-companies master data.
 */
export const fetchTypeOfCompaniesMasterData = (): Promise<Master[]> => {
  return fetchMasterData(MasterdataEnum.TYPE_OF_COMPANIES, 'Failed to fetch type of companies');
};

/**
 * Fetches the geographic-areas masterdata from the API.
 * @returns {Promise<Master[]>} - A promise that resolves to an array of geographic-areas master data.
 */
export const fetchGeographicAreasMasterData = (): Promise<Master[]> => {
  return fetchMasterData(MasterdataEnum.GEOGRAPHIC_AREAS, 'Failed to fetch geographic areas');
};

/**
 * Fetches the business-duration masterdata from the API.
 * @returns {Promise<Master[]>} - A promise that resolves to an array of business-duration master data.
 */
export const fetchBusinessDurationMasterData = (): Promise<Master[]> => {
  return fetchMasterData(MasterdataEnum.BUSINESS_DURATION, 'Failed to fetch business duration');
};

/**
  Fetches the product list for onboarding the users
  @returns {Promise<QuoteStatus[]>} - A promise that resolves to an array of product list.
  @throws {Error} - Throws an error if the request fails.
*/

export const fetchProductList = async (url: string): Promise<ProductList[]> => {
  try {
    const api_url = getUrl(url);

    const { data } = await axiosInstance.get<ProductListResponse>(api_url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch product list');
  }
};
