import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FieldConfig, InputFieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { PotentialProductOffered } from '../../../../common/interfaces/partnerOnboarding/PartnerOnboarding';
import { TngGrid, TngLoader } from '../../../../components/common';
import FieldRendering from '../../../../components/CustomFormFields/FieldRendering';
import { RootState } from '../../../../store';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../../context/ContextProvider';
import { ContextActionEnum } from '../../enums/ContextActionEnums';
import { useUpdateDetailsForOnboardedPartner } from '../../hooks/useUpdateDetailsForOnboardedPartner';
import { mapContextDataForUpdate } from '../../utils/mapContextDataForUpdate';
import { initializeProductsOfferForm } from '../helper/initializeProductOfferForm';
import { GetInputFieldConfig } from '../helper/inputFieldConfig';
import { useFetchProductLists } from '../hooks/useProductList';
import { ProductOffer } from '../interface';

interface ProductOffersFormProps {
  bottomPanelRef?: React.RefObject<BottomPanelRef>;
}

interface BottomPanelRef {
  setNextButtonState: (state: boolean) => void;
  setLoadingState: (state: boolean) => void;
}

const ProductOffersForm = forwardRef<unknown, ProductOffersFormProps>(({ bottomPanelRef }, ref) => {
  const { leadId } = useSelector((state: RootState) => state.partner);
  const partnerOnboardingDispatch = usePartnerOnboardingDispatch();
  const {
    companyInformation,
    productsOffer,
    revenueTarget,
    termsConditions,
    stepsCompleted,
    moreCompanyDetails,
    activeStep,
  } = usePartnerOnboardingState();

  const {
    data: { WORKFLOW_AUTOMATION, INVOICE_AUTOMATION, DOCUMENT_AUTOMATION },
    isLoading,
  } = useFetchProductLists();

  const fieldConfig: FieldConfig[] = GetInputFieldConfig(
    WORKFLOW_AUTOMATION,
    INVOICE_AUTOMATION,
    DOCUMENT_AUTOMATION,
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
  } = useForm<ProductOffer>({
    defaultValues: initializeProductsOfferForm(productsOffer),
  });

  const { updateNewPartnerDetails, loading } = useUpdateDetailsForOnboardedPartner();

  const onSubmit: SubmitHandler<ProductOffer> = async (data: ProductOffer) => {
    try {
      const { documentAutomationProducts, invoiceAutomationProducts, workflowAutomationProducts } =
        data;

      const productsOffered: PotentialProductOffered = {
        documentAutomationProducts,
        invoiceAutomationProducts,
        workflowAutomationProducts,
      };

      const productsOfferedFormData = mapContextDataForUpdate(
        companyInformation,
        moreCompanyDetails,
        productsOffered,
        revenueTarget,
        termsConditions,
        stepsCompleted,
        activeStep,
      );

      const { success, error } = await updateNewPartnerDetails(
        productsOfferedFormData,
        leadId ?? null,
        isDirty,
      );
      if (success) {
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PRODUCTS_OFFER,
          payload: productsOffered,
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.UPDATE_ACTIVE_STEP,
          payload: activeStep + 1,
        });
        const isUpdating = activeStep < stepsCompleted;
        if (!isUpdating) {
          partnerOnboardingDispatch({
            type: ContextActionEnum.UPDATE_STEP_COMPLETED,
            payload: stepsCompleted + 1,
          });
        }
      } else {
        throw new Error(error);
      }
    } catch (error: any) {
      /* empty */
    }
  };

  const field1 = watch('workflowAutomationProducts');
  const field2 = watch('invoiceAutomationProducts');
  const field3 = watch('documentAutomationProducts');

  const isFormDirty = field1.length > 0 || field2.length > 0 || field3.length > 0;

  useEffect(() => {
    if (isFormDirty) {
      bottomPanelRef?.current?.setNextButtonState(false);
    } else {
      bottomPanelRef?.current?.setNextButtonState(true);
    }
  }, [bottomPanelRef, isFormDirty]);

  useEffect(() => {
    if (loading) {
      bottomPanelRef?.current?.setLoadingState(true);
    } else {
      bottomPanelRef?.current?.setLoadingState(false);
    }
  }, [bottomPanelRef, loading]);

  // Expose the `onSubmit` handler to the parent using `useImperativeHandle`.
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)(); // Trigger form submission
    },
  }));

  return (
    <>
      {isLoading ? (
        <TngLoader />
      ) : (
        <TngGrid className="product_offers_form_container">
          <form aria-label="Products you want to offer" className="product_offers_form">
            <TngGrid className="product_offers_form_fields">
              {fieldConfig?.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
                <TngGrid className="form_row" key={rowIndex}>
                  <FieldRendering
                    fieldRow={fieldRow}
                    errors={errors}
                    register={register}
                    control={control}
                  />
                </TngGrid>
              ))}
            </TngGrid>
          </form>
        </TngGrid>
      )}
    </>
  );
});

ProductOffersForm.displayName = 'ProductOffersForm';

export default ProductOffersForm;
