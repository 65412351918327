import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Translations from '../../../../assets/locale/en/content.json';
import {
  TUNGSTEN_PRIVACY_POLICY_URL,
  TUNGSTEN_TERMS_OF_USE_URL,
} from '../../../../common/constant';
import { FieldConfig, InputFieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { TermsConditions } from '../../../../common/interfaces/partnerOnboarding/TermsConditions';
import { TngGrid, TngLink, TngTypography } from '../../../../components/common';
import FieldRendering from '../../../../components/CustomFormFields/FieldRendering';
import { RootState } from '../../../../store';
import { usePartnerOnboardingState } from '../../context/ContextProvider';
import { useUpdateDetailsForOnboardedPartner } from '../../hooks/useUpdateDetailsForOnboardedPartner';
import { mapContextDataForUpdate } from '../../utils/mapContextDataForUpdate';
import { initializeTermsForm } from '../helper/initializeTermsForm';
import { GetInputFieldConfig } from '../helper/inputFieldConfig';
import { TermsAndConditions } from '../interface';

interface TermsAndConditionFormProps {
  bottomPanelRef?: React.RefObject<BottomPanelRef>;
}

interface BottomPanelRef {
  setNextButtonState: (state: boolean) => void;
  setLoadingState: (state: boolean) => void;
}

const TermsAndConditionForm = forwardRef<unknown, TermsAndConditionFormProps>(
  ({ bottomPanelRef }, ref) => {
    const navigate = useNavigate();
    const { leadId } = useSelector((state: RootState) => state.partner);
    const {
      activeStep,
      companyInformation,
      productsOffer,
      revenueTarget,
      stepsCompleted,
      moreCompanyDetails,
    } = usePartnerOnboardingState();
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
      control,
    } = useForm<TermsAndConditions>({
      defaultValues: initializeTermsForm(),
    });

    const { updateNewPartnerDetails, loading } = useUpdateDetailsForOnboardedPartner();

    const fieldConfig: FieldConfig[] = GetInputFieldConfig();

    const isAgreeTermsAndConditionField = watch('isAgreeTermsAndCondition');

    const onSubmit: SubmitHandler<TermsAndConditions> = async (data: TermsAndConditions) => {
      try {
        const { isAgreeTermsAndCondition, isRequestMarketing } = data;
        const termsAndConditions: TermsConditions = {
          isAgreeTermsAndCondition,
          isRequestMarketing,
        };

        const termsConditionsFormData = mapContextDataForUpdate(
          companyInformation,
          moreCompanyDetails,
          productsOffer,
          revenueTarget,
          termsAndConditions,
          stepsCompleted,
          activeStep,
        );

        const { success, error } = await updateNewPartnerDetails(
          termsConditionsFormData,
          leadId ?? null,
        );
        if (success) {
          // Redirect to dashboard
          navigate('/dashboard');
        } else {
          throw new Error(error);
        }
      } catch (error: any) {
        /* empty */
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit)();
      },
    }));

    useEffect(() => {
      if (isAgreeTermsAndConditionField) {
        bottomPanelRef?.current?.setNextButtonState(false);
      } else {
        bottomPanelRef?.current?.setNextButtonState(true);
      }
    }, [bottomPanelRef, isAgreeTermsAndConditionField]);

    useEffect(() => {
      if (loading) {
        bottomPanelRef?.current?.setLoadingState(true);
      } else {
        bottomPanelRef?.current?.setLoadingState(false);
      }
    }, [bottomPanelRef, loading]);

    return (
      <>
        <TngGrid className="terms_and_conditions_form_container">
          <form aria-label="Terms And Conditions" className="terms_and_conditions_form">
            <TngGrid className="terms_and_conditions_form_fields">
              {fieldConfig?.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
                <TngGrid className="form_row" key={rowIndex}>
                  <FieldRendering
                    fieldRow={fieldRow}
                    errors={errors}
                    register={register}
                    control={control}
                  />
                </TngGrid>
              ))}
            </TngGrid>
          </form>
          <TngGrid className="terms_and_conditions_concent">
            <TngTypography className="text" component="span" variant="body1">
              {Translations.partnerOnboarding.termAndConditionConcent.initialText}
            </TngTypography>
            <TngLink className="link" href={TUNGSTEN_TERMS_OF_USE_URL} target="_blank">
              {Translations.partnerOnboarding.termAndConditionConcent.termOfUse}
            </TngLink>
            <TngTypography className="text" component="span" variant="body1">
              {Translations.partnerOnboarding.termAndConditionConcent.and}
            </TngTypography>
            <TngLink className="link" href={TUNGSTEN_PRIVACY_POLICY_URL} target="_blank">
              {Translations.partnerOnboarding.termAndConditionConcent.privacyPolicy}
            </TngLink>
            <TngTypography className="text" component="span" variant="body1">
              {Translations.partnerOnboarding.termAndConditionConcent.endText}
            </TngTypography>
          </TngGrid>
        </TngGrid>
      </>
    );
  },
);

TermsAndConditionForm.displayName = 'TermsAndConditionForm';

export default TermsAndConditionForm;
