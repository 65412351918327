import { useQuery } from '@tanstack/react-query';

import { fetchPartnerOnboardingDetails } from '../../api/services/partnerOnboarding/partnerOnboardingService';
import { PartnerOnboarding } from '../interfaces/partnerOnboarding/PartnerOnboarding';

/**
 * Custom hook to fetch partner onboarding details using React Query.
 * @param {string} leadId - The ID of the lead for fetching onboarding details.
 * @returns {object} - An object containing data, error, and loading state.
 */
export const useGetPartnerOnboarding = (isEnable: boolean, leadId: string | null) => {
  const { data, error, isLoading } = useQuery<PartnerOnboarding, Error>({
    queryKey: ['partnerOnboarding', leadId],
    queryFn: () => fetchPartnerOnboardingDetails(leadId!),
    enabled: !!isEnable,
  });
  return { data, error, isLoading };
};
