import React, { useEffect, useRef } from 'react';

import TuneIcon from '@mui/icons-material/Tune';

import TngDataGrid from './components/TngDataGrid/TngDataGrid';
import TngDataGridPagination from './components/TngDataGridPagination/TngDataGridPagination';
import TngFilterPanel from './components/TngFilterPanel/TngFilterPanel';
import TngSearchBy from './components/TngSearchBy/TngSearchBy';
import useDataGridhook from './hooks/useDataGridHook';
import Translations from '../../../assets/locale/en/content.json';
import { GridActionEnum } from '../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../common/GridContext/GridContextProvider';
import { FindGridConfig } from '../../../common/interfaces/FindGridConfig';
import { TngButton, TngDivider, TngGrid, TngIconButton, TngTypography } from '../../common';

interface TngFindSectionProps {
  gridConfig: FindGridConfig;
  filterComponent?: React.ReactElement<Partial<any>>;
  filterCount?: number;
}

const TngAgGrid: React.FC<TngFindSectionProps> = ({ gridConfig, filterComponent, filterCount }) => {
  const dispatch = useGridDispatch();
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const { isFilterApplied, isFilterOpen } = useGridState();
  const {
    baseUrl,
    ColumnHeader,
    search,
    moreOptions,
    sorting,
    columnWidth,
    rowHeight,
    isPaginationVisible,
  } = gridConfig;

  // use hook to called the api
  const { data } = useDataGridhook({ baseUrl });

  const handleFilterOpen = () => {
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: true });
  };

  const handleFilterClose = () => {
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
  };

  const handleClearFilter = () => {
    // Clear filters logic here
    dispatch({ type: GridActionEnum.SET_FILTERS, payload: {} });
    dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
    // re-focus to filter button
    if (filterButtonRef.current) {
      filterButtonRef.current.focus();
    }
  };

  // If data is present, then use dispatch to set data on context
  useEffect(() => {
    if (data) {
      dispatch({ type: GridActionEnum.SET_GRID_DATA, payload: data.data });
      dispatch({ type: GridActionEnum.SET_PAGE_SIZE, payload: data.pageSize ?? 1 });
      dispatch({ type: GridActionEnum.SET_PAGE_NUMBER, payload: data.pageNumber ?? 1 });
      dispatch({ type: GridActionEnum.SET_TOTAL_PAGE, payload: data.totalPages ?? 1 });
      dispatch({
        type: GridActionEnum.SET_TOTAL_RECORD,
        payload: data.totalRecords ?? data.data.length,
      });
    }
  }, [data, dispatch]);

  // use this to set data from config to context
  // only once
  useEffect(() => {
    if (search.isDropdownApplicable && search.defaultSearch) {
      dispatch({ type: GridActionEnum.SET_SEARCH, payload: search.defaultSearch });
    }
    if (sorting.isApplicable && sorting.defaultSorting) {
      dispatch({ type: GridActionEnum.SET_SORTING, payload: sorting.defaultSorting });
    }

    dispatch({ type: GridActionEnum.SET_IS_DEFAULT_VALUE_APPLIED, payload: true });
  }, []);

  return (
    <>
      <TngGrid container>
        <TngGrid item>{search.isApplicable && <TngSearchBy searchConfig={search} />}</TngGrid>
        {filterComponent && (
          <TngGrid item className="filter_button_container">
            <TngGrid
              item
              className={`${!isFilterApplied ? 'filter_notapplied' : 'filter_applied'} filter_button_grid`}
            >
              <TngIconButton
                disableRipple
                disableTouchRipple
                aria-label={isFilterApplied ? 'Filter Applied' : 'Filter'}
                onClick={handleFilterOpen}
                ref={filterButtonRef} // Assign ref here
                className="filter_button"
              >
                <TuneIcon />
                {isFilterApplied && `${Translations.filterSection.filterApplied} (${filterCount})`}
              </TngIconButton>
            </TngGrid>
            {isFilterApplied && (
              <TngGrid item className="filter_clear">
                <TngButton variant="outlined" aria-label="Clear Filter" onClick={handleClearFilter}>
                  <TngTypography variant="body1">
                    {Translations.filterSection.clearFilter}
                  </TngTypography>
                </TngButton>
              </TngGrid>
            )}
          </TngGrid>
        )}
      </TngGrid>
      {filterComponent && (
        <TngFilterPanel
          open={isFilterOpen}
          onClose={handleFilterClose}
          filterComponent={filterComponent}
        />
      )}
      <TngDataGrid
        ColumnHeader={ColumnHeader}
        sortingConfig={sorting}
        moreOptions={moreOptions}
        columnWidth={columnWidth}
        rowHeight={rowHeight}
      />
      <TngDivider className="order_history_divider" />
      {isPaginationVisible && <TngDataGridPagination />}
    </>
  );
};

export default TngAgGrid;
