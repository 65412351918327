export enum UserTypesEmuns {
  ERP_PPU = 'ERP - Partner Primary Program User',
  ERP_PSU = 'ERP - Partner Primary Sales User',
  ERP_PTU = 'ERP - Partner Primary Technical User',
  ERP_GU = 'ERP - Generic User',
  ERP_GTU = 'ERP - Partner Generic Technical User',
  PTU = 'Partner Primary Temporary User',
}

export enum PartnerOnboardingStatus {
  NEW = 'New',
  PENDING = 'Pending',
}
