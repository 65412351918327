import React from 'react';

const ManageOpportunitiesIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.10742 15.4432L8.25028 20.625L21.1075 6.375"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M2.25 7.5H8.25" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M2.25 3H14.25" stroke="#8094AA" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
};

export default ManageOpportunitiesIcon;
