import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { CountryOptions } from '../../../../common/interfaces/CountryOptions';
import { FieldConfig, InputFieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { CompanyInfoSaveForm } from '../../../../common/interfaces/partnerOnboarding/CompanyInfo';
import { StateOptions } from '../../../../common/interfaces/StateOptions';
import { countryDropdownMapper } from '../../../../common/mappers/CountryDropdownMapper';
import { stateDropdownMapper } from '../../../../common/mappers/StateDropdownMapper';
import { TngGrid } from '../../../../components/common';
import FieldRendering from '../../../../components/CustomFormFields/FieldRendering';
import { RootState } from '../../../../store';
import { setPartnerApplication } from '../../../../store/partner/partnerReducer';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../../context/ContextProvider';
import { ContextActionEnum } from '../../enums/ContextActionEnums';
import { useCreateLeadForPartner } from '../../hooks/useCreateLeadForPartner';
import { useUpdateDetailsForOnboardedPartner } from '../../hooks/useUpdateDetailsForOnboardedPartner';
import { mapContextDataForUpdate } from '../../utils/mapContextDataForUpdate';
import { companyInfoFormMapper } from '../helper/companyInfoFormMapper';
import { initializeCompanyInfoForm } from '../helper/initializeCompanyInfoForm';
import { GetInputFieldConfig } from '../helper/InputFieldConfig';
import { CompanyInfoField } from '../interfaces';

interface CompanyInfoFormProps {
  bottomPanelRef?: React.RefObject<BottomPanelRef>; // Add this line to accept bottomPanelRef
}

interface BottomPanelRef {
  setNextButtonState: (state: boolean) => void;
  setLoadingState: (state: boolean) => void;
}

const CompanyInfoForm = forwardRef<unknown, CompanyInfoFormProps>(({ bottomPanelRef }, ref) => {
  const {
    companyInformation,
    moreCompanyDetails,
    productsOffer,
    revenueTarget,
    termsConditions,
    stepsCompleted,
    activeStep,
  } = usePartnerOnboardingState();
  const masterState = useSelector((state: RootState) => state.master);
  const { firstName, lastName, email } = useSelector((state: RootState) => state.auth);
  const { leadId } = useSelector((state: RootState) => state.partner);

  const partnerOnboardingDispatch = usePartnerOnboardingDispatch();
  const dispatch = useDispatch();

  // get countries and states values from redux
  const { countries, states } = masterState;
  // state to manage default values for form
  const [initialCompanyInfo, setInitialCompanyInfo] = useState<CompanyInfoField>(
    initializeCompanyInfoForm(),
  );

  // states to store country and state options
  const [countryOptions, setCountryOptions] = useState<CountryOptions[]>([] as CountryOptions[]);
  const [filteredStates, setFilteredStates] = useState<StateOptions[]>([] as StateOptions[]);
  // state to store selected country Id
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    reset,
  } = useForm<CompanyInfoField>({ defaultValues: initializeCompanyInfoForm() });

  const { createLeadForPartner, loading } = useCreateLeadForPartner();
  const { updateNewPartnerDetails, loading: updateLoading } = useUpdateDetailsForOnboardedPartner();

  useEffect(() => {
    if (companyInformation && Object.keys(companyInformation).length > 0) {
      const mappedData = companyInfoFormMapper(companyInformation);
      setInitialCompanyInfo(mappedData);
      reset(mappedData);
    }
  }, [companyInformation, reset]);

  //to update countries to its corresponding react state
  useEffect(() => {
    if (countries.length) {
      const mappedCountries = countryDropdownMapper(countries);
      setCountryOptions(mappedCountries);
    }
  }, [countries]);

  //to update selected country Id on react state as soon as data in there in initialProfileValues.country
  useEffect(() => {
    const userSelectedCountry: string = initialCompanyInfo?.country;
    if (userSelectedCountry) {
      const selectedCountryId =
        countries.length && countries?.find((country) => country.code === userSelectedCountry)?.id;
      if (selectedCountryId) setSelectedCountry(selectedCountryId);
    }
  }, [countries, initialCompanyInfo?.country]);

  //to update states to its corresponding react state
  useEffect(() => {
    if (states.length) {
      const mappedStates = stateDropdownMapper(states, selectedCountry);
      setFilteredStates(mappedStates);
    }
  }, [selectedCountry, states]);

  const fieldConfig: FieldConfig[] = GetInputFieldConfig(
    countryOptions,
    filteredStates,
    selectedCountry,
  );

  const dropdownCallback = (field: InputFieldConfig, e: any) => {
    if (field.htmlFor === 'country') {
      setValue('state', '');
      const selectedOption = field.options?.find((option) => option.value === e.target.value)?.id;
      if (selectedOption) setSelectedCountry(selectedOption!); // Call the custom onChange
    }
  };
  const onSubmit: SubmitHandler<CompanyInfoField> = async (data: CompanyInfoField) => {
    try {
      const {
        companyName,
        websiteURL,
        country,
        state,
        city,
        street,
        postalCode,
        phoneNumber,
        fax,
        jobTitle,
      } = data;

      const baseProfile = {
        companyName,
        websiteURL,
        jobTitle: jobTitle!,
        phoneNumber,
        fax: fax!,
        firstName: firstName!,
        lastName: lastName!,
        emailAddress: email!,
      };

      const address = {
        countryCode: country,
        stateCode: state!,
        street,
        city,
        postalCode: postalCode!,
      };
      if (leadId) {
        const leadData = mapContextDataForUpdate(
          { ...baseProfile, ...address },
          moreCompanyDetails,
          productsOffer,
          revenueTarget,
          termsConditions,
          stepsCompleted,
          activeStep,
        );

        const { success, error } = await updateNewPartnerDetails(leadData, leadId ?? null, isDirty);
        if (success) {
          partnerOnboardingDispatch({
            type: ContextActionEnum.SET_COMPANY_INFORMATION,
            payload: { ...baseProfile, ...address },
          });
          partnerOnboardingDispatch({
            type: ContextActionEnum.UPDATE_ACTIVE_STEP,
            payload: activeStep + 1,
          });
          const isUpdating = activeStep < stepsCompleted;
          if (!isUpdating) {
            partnerOnboardingDispatch({
              type: ContextActionEnum.UPDATE_STEP_COMPLETED,
              payload: stepsCompleted + 1,
            });
          }
        } else {
          throw new Error(error);
        }
      } else {
        const newProfile: CompanyInfoSaveForm = {
          ...baseProfile,
          address,
          stepsCompleted: 1,
        };
        const { leadId, success, error } = await createLeadForPartner(newProfile);

        if (success) {
          partnerOnboardingDispatch({
            type: ContextActionEnum.SET_COMPANY_INFORMATION,
            payload: { ...baseProfile, ...address },
          });
          dispatch(setPartnerApplication({ leadId, status: 'New' }));
        } else {
          throw new Error(error);
        }
      }
      reset(data);
    } catch (error: any) {
      /* empty */
    }
  };

  useEffect(() => {
    if (isValid) {
      bottomPanelRef?.current?.setNextButtonState(false);
    } else {
      bottomPanelRef?.current?.setNextButtonState(true);
    }
  }, [isValid, bottomPanelRef]);

  useEffect(() => {
    if (loading || updateLoading) {
      bottomPanelRef?.current?.setLoadingState(true);
    } else {
      bottomPanelRef?.current?.setLoadingState(false);
    }
  }, [loading, updateLoading, bottomPanelRef]);

  // Expose the `onSubmit` handler to the parent using `useImperativeHandle`.
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)(); // Trigger form submission
    },
  }));

  return (
    <TngGrid className="company_info_form_container">
      <form aria-label="Company Information" className="company_info_form">
        <TngGrid className="company_info_form_fields">
          {fieldConfig.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
            <TngGrid className="form_row" key={rowIndex}>
              <FieldRendering
                fieldRow={fieldRow}
                errors={errors}
                register={register}
                control={control}
                dropdownCallback={dropdownCallback}
              />
            </TngGrid>
          ))}
        </TngGrid>
      </form>
    </TngGrid>
  );
});

CompanyInfoForm.displayName = 'CompanyInfoForm';

export default CompanyInfoForm;
