import React from 'react';

import logo from '../../../../assets/images/tungsten_logo.svg';
import underlineTheame from '../../../../assets/images/underline_theme.svg';
import Translations from '../../../../assets/locale/en/content.json';
import { TngDivider, TngGrid, TngImage, TngTypography } from '../../../common';

const TngTextOverlayImage = () => {
  return (
    <TngGrid item className="text_overlay_image">
      <TngGrid className="static_content">
        <TngGrid className="static_content_upper_grid">
          <TngImage className="content_logo" src={logo} alt="logo Image" />
          <TngDivider orientation="vertical" />
          <TngTypography className="content_header">
            {Translations.Login.partnerPortal}
          </TngTypography>
        </TngGrid>
        <TngImage className="underline_theame" src={underlineTheame} alt="underline_theame Image" />
        <TngTypography className="content_text">
          {Translations.Login.textOverflowText}
        </TngTypography>
      </TngGrid>
    </TngGrid>
  );
};

export default TngTextOverlayImage;
