import { getColumnHeaders } from './columnDetails';
import { getProductListUrl } from '../../../../../api/urlGenerator';
import Translations from '../../../../../assets/locale/en/content.json';
import { Sorting } from '../../../../../common/enums/Sorting';
import { FindGridConfig } from '../../../../../common/interfaces/FindGridConfig';
import { ProductListGridUrl } from '../../../../../common/interfaces/urls/ProductListGridUrl';

// grid config contains the details about grid which will render for this screen
export const getGridConfig = (
  apiParams: ProductListGridUrl,
  orderId: string,
  isUserPTU: boolean,
): FindGridConfig => ({
  baseUrl: orderId ? getProductListUrl(apiParams, orderId) : null,
  ColumnHeader: getColumnHeaders(isUserPTU),
  search: {
    isApplicable: true,
    isDropdownApplicable: false,
    defaultSearch: { searchBy: 'productName', searchValue: '' },
    placeholder: Translations.OrderSummary.productListGridSearchPlaceholder,
  },
  moreOptions: {
    isApplicable: false,
    moreOptionNavigation: [],
  },
  rowHeight: 75,
  columnWidth: 160,
  sorting: {
    isApplicable: true,
    defaultSorting: { colId: 'productName', sortOrder: Sorting.ASC },
  },
  isPaginationVisible: true,
});
