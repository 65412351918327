import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Translations from '../../../../../assets/locale/en/content.json';
import { CountryOptions } from '../../../../../common/interfaces/CountryOptions';
import { InputFieldConfig } from '../../../../../common/interfaces/InputFieldConfig';
import { StateOptions } from '../../../../../common/interfaces/StateOptions';
import { SaveUserProfile } from '../../../../../common/interfaces/user/UserProfile';
import { countryDropdownMapper } from '../../../../../common/mappers/CountryDropdownMapper';
import { stateDropdownMapper } from '../../../../../common/mappers/StateDropdownMapper';
import { TngGrid, TngLoader } from '../../../../../components/common';
import FieldRendering from '../../../../../components/CustomFormFields/FieldRendering';
import FormButtons from '../../../../../components/CustomFormFields/FormButton';
import { RootState } from '../../../../../store';
import { initialFormData } from '../helper/initialFormData';
import { GetInputFieldConfig } from '../helper/inputFieldConfig';
import { useSaveUserProfile } from '../hooks/useSaveUserProfile';
import { FieldConfig, ProfileFormField } from '../interfaces/ProfileFormField';

const ProfileForm = () => {
  const userState = useSelector((state: RootState) => state.user);
  const { userProfile } = useSelector((state: RootState) => state.user.permissions);

  const haveEditPermission = userProfile?.edit ?? false;
  const profile = userState.user;
  const contactId = userState.user.contactId;
  // state to manage default values for form
  const [initialProfileValues, setInitialProfileValues] =
    useState<ProfileFormField>(initialFormData());

  // state for page loader
  const [pageLoader, setPageLoader] = useState<boolean>(true);

  // states to store country and state options
  const [countryOptions, setCountryOptions] = useState<CountryOptions[]>([] as CountryOptions[]);
  const [filteredStates, setFilteredStates] = useState<StateOptions[]>([] as StateOptions[]);

  // state to store selected country Id
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);

  // Fetching data from redux store
  const masterState = useSelector((state: RootState) => state.master);
  const { countries, states } = masterState;

  // defining react hook form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    reset,
  } = useForm<ProfileFormField>({ defaultValues: initialProfileValues });
  const fieldConfig: FieldConfig[] = GetInputFieldConfig(
    countryOptions,
    filteredStates,
    selectedCountry,
  );

  // hook to save user profile data
  const { saveUser, loading: saveProfileLoading } = useSaveUserProfile();

  //  to update profile data to its corresponding react state
  useEffect(() => {
    if (profile && Object.keys(profile).length > 0) {
      setInitialProfileValues(profile);
      reset(profile);
      setPageLoader(false);
    }
  }, [profile, reset]);

  // Reset the form after the profile has been set
  useEffect(() => {
    if (!pageLoader) reset(initialProfileValues);
  }, [initialProfileValues, pageLoader, profile, reset]);

  //to update countries to its corresponding react state
  useEffect(() => {
    if (countries.length) {
      const mappedCountries = countryDropdownMapper(countries);
      setCountryOptions(mappedCountries);
    }
  }, [countries]);

  //to update selected country Id on react state as soon as data in there in initialProfileValues.country
  useEffect(() => {
    const userSelectedCountry: string = initialProfileValues?.country;
    if (userSelectedCountry) {
      const selectedCountryId =
        countries.length && countries?.find((country) => country.code === userSelectedCountry)?.id;
      if (selectedCountryId) setSelectedCountry(selectedCountryId);
    }
  }, [countries, initialProfileValues?.country]);

  //to update states to its corresponding react state
  useEffect(() => {
    if (states.length) {
      const mappedStates = stateDropdownMapper(states, selectedCountry);
      setFilteredStates(mappedStates);
    }
  }, [selectedCountry, states]);

  const dropdownCallback = (field: InputFieldConfig, e: any) => {
    if (field.htmlFor === 'country') {
      setValue('state', '');
      const selectedOption = field.options?.find((option) => option.value === e.target.value)?.id;
      if (selectedOption) setSelectedCountry(selectedOption!); // Call the custom onChange
    }
  };

  // submit handler which save form
  const onSubmit: SubmitHandler<ProfileFormField> = async (data: ProfileFormField) => {
    try {
      const {
        firstName,
        lastName,
        jobTitle,
        phoneNumber,
        emailAddress,
        country,
        state,
        street,
        city,
        postalCode,
      } = data;
      const updatedProfile: SaveUserProfile = {
        firstName,
        lastName,
        jobTitle: jobTitle!,
        phoneNumber,
        emailAddress,
        address: { countryCode: country, stateCode: state, street, city, postalCode: postalCode! },
      };

      await saveUser(updatedProfile, contactId);
      reset(data);
    } catch (error: any) {
      /* empty */
    }
  };
  return (
    <>
      {pageLoader ? (
        <TngLoader />
      ) : (
        <form aria-label="My Profile" className="profile_form" onSubmit={handleSubmit(onSubmit)}>
          <TngGrid className="profile_form_fields">
            {fieldConfig.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
              <TngGrid className="form_row" key={rowIndex}>
                <FieldRendering
                  fieldRow={fieldRow}
                  errors={errors}
                  register={register}
                  control={control}
                  dropdownCallback={dropdownCallback}
                />
              </TngGrid>
            ))}
          </TngGrid>
          {haveEditPermission && (
            <FormButtons
              isFormUpdated={isDirty}
              loading={saveProfileLoading}
              btnLabel={Translations.AccountSettingsProfile.saveChanges}
            />
          )}
        </form>
      )}
    </>
  );
};

export default ProfileForm;
