import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FieldConfig, InputFieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { MoreCompanyDetails } from '../../../../common/interfaces/partnerOnboarding/MoreCompanyDetails';
import { TngGrid, TngLoader } from '../../../../components/common';
import FieldRendering from '../../../../components/CustomFormFields/FieldRendering';
import { RootState } from '../../../../store';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../../context/ContextProvider';
import { ContextActionEnum } from '../../enums/ContextActionEnums';
import { useUpdateDetailsForOnboardedPartner } from '../../hooks/useUpdateDetailsForOnboardedPartner';
import { mapContextDataForUpdate } from '../../utils/mapContextDataForUpdate';
import { initializeCompanyDetailForm } from '../helper/initializeCompanyDetailForm';
import { GetInputFieldConfig } from '../helper/inputFieldConfig';
import { useFetchMoreCompanyDetails } from '../hooks/useFetchMoreCompanyDetails';
import { MoreCompanyDetailsFormFields } from '../interface';

interface MoreCompanyDetailsFormProps {
  bottomPanelRef?: React.RefObject<BottomPanelRef>;
}

interface BottomPanelRef {
  setNextButtonState: (state: boolean) => void;
  setLoadingState: (state: boolean) => void;
}

const MoreCompanyDetailsForm = forwardRef<unknown, MoreCompanyDetailsFormProps>(
  ({ bottomPanelRef }, ref) => {
    const { leadId } = useSelector((state: RootState) => state.partner);
    const partnerOnboardingDispatch = usePartnerOnboardingDispatch();
    const {
      companyInformation,
      productsOffer,
      revenueTarget,
      termsConditions,
      stepsCompleted,
      moreCompanyDetails,
      activeStep,
    } = usePartnerOnboardingState();

    const {
      data: {
        TYPE_OF_COMPANIES,
        PRIMARY_BUSINESS,
        GEOGRAPHIC_AREAS,
        BUSINESS_DURATION,
        TARGET_MARKETS,
      },
      isLoading,
    } = useFetchMoreCompanyDetails();

    const fieldConfig: FieldConfig[] = GetInputFieldConfig(
      TYPE_OF_COMPANIES,
      PRIMARY_BUSINESS,
      GEOGRAPHIC_AREAS,
      BUSINESS_DURATION,
      TARGET_MARKETS,
    );

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isDirty },
      control,
    } = useForm<MoreCompanyDetailsFormFields>({
      defaultValues: initializeCompanyDetailForm(moreCompanyDetails),
    });

    const { updateNewPartnerDetails, loading } = useUpdateDetailsForOnboardedPartner();

    const onSubmit: SubmitHandler<MoreCompanyDetailsFormFields> = async (
      data: MoreCompanyDetailsFormFields,
    ) => {
      try {
        const {
          typeOfCompany,
          primaryTypeOfBusiness,
          geographicAreaServed,
          lengthInTimeInBusiness,
          targetMarkets,
          numberOfEmployees,
          isSellingThroughOtherResellers,
        } = data;

        const moreCompanyDetails: MoreCompanyDetails = {
          typeOfCompany: typeOfCompany[0],
          primaryTypeOfBusiness: primaryTypeOfBusiness[0],
          geographicAreaServed: geographicAreaServed[0],
          lengthInTimeInBusiness: lengthInTimeInBusiness[0],
          targetMarkets,
          numberOfEmployees,
          isSellingThroughOtherResellers: isSellingThroughOtherResellers[0] === 'Yes',
        };

        const moreCompanyDetailsFormData = mapContextDataForUpdate(
          companyInformation,
          moreCompanyDetails,
          productsOffer,
          revenueTarget,
          termsConditions,
          stepsCompleted,
          activeStep,
        );

        const { success, error } = await updateNewPartnerDetails(
          moreCompanyDetailsFormData,
          leadId ?? null,
          isDirty,
        );
        if (success) {
          partnerOnboardingDispatch({
            type: ContextActionEnum.SET_MORE_COMPANY_DETAILS,
            payload: moreCompanyDetails,
          });
          partnerOnboardingDispatch({
            type: ContextActionEnum.UPDATE_ACTIVE_STEP,
            payload: activeStep + 1,
          });
          const isUpdating = activeStep < stepsCompleted;
          if (!isUpdating) {
            partnerOnboardingDispatch({
              type: ContextActionEnum.UPDATE_STEP_COMPLETED,
              payload: stepsCompleted + 1,
            });
          }
        } else {
          throw new Error(error);
        }
      } catch (error: any) {
        /* empty */
      }
    };

    const field1 = watch('typeOfCompany');
    const field2 = watch('primaryTypeOfBusiness');
    const field3 = watch('geographicAreaServed');
    const field4 = watch('lengthInTimeInBusiness');
    const field5 = watch('targetMarkets');
    const field6 = watch('numberOfEmployees');
    const field7 = watch('isSellingThroughOtherResellers');

    const isFormDirty =
      field1.length > 0 &&
      field2.length > 0 &&
      field3.length > 0 &&
      field4.length > 0 &&
      field5.length > 0 &&
      field6 &&
      field7.length > 0;

    useEffect(() => {
      if (isFormDirty) {
        bottomPanelRef?.current?.setNextButtonState(false);
      } else {
        bottomPanelRef?.current?.setNextButtonState(true);
      }
    }, [isFormDirty, bottomPanelRef]);

    useEffect(() => {
      if (loading) {
        bottomPanelRef?.current?.setLoadingState(true);
      } else {
        bottomPanelRef?.current?.setLoadingState(false);
      }
    }, [loading, bottomPanelRef]);

    // Expose the `onSubmit` handler to the parent using `useImperativeHandle`.
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit)(); // Trigger form submission
      },
    }));

    return (
      <>
        {isLoading ? (
          <TngLoader />
        ) : (
          <TngGrid className="more_company_details_form_container">
            <form aria-label="More Company Details" className="more_company_details_form">
              <TngGrid className="more_company_details_form_fields">
                {fieldConfig?.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
                  <TngGrid className="form_row" key={rowIndex}>
                    <FieldRendering
                      fieldRow={fieldRow}
                      errors={errors}
                      register={register}
                      control={control}
                    />
                  </TngGrid>
                ))}
              </TngGrid>
            </form>
          </TngGrid>
        )}
      </>
    );
  },
);

MoreCompanyDetailsForm.displayName = 'MoreCompanyDetailsForm';

export default MoreCompanyDetailsForm;
