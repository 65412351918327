import { ContextActions } from '../interfaces/contextAction';
import { ContextState } from '../interfaces/contextState';

// Action types
export const SET_PREV_BUTTON_PROPS = 'SET_PREV_BUTTON_PROPS';
export const SET_NEXT_BUTTON_PROPS = 'SET_NEXT_BUTTON_PROPS';
export const SET_COMPANY_INFORMATION = 'SET_COMPANY_INFORMATION';
export const SET_MORE_COMPANY_DETAILS = 'SET_MORE_COMPANY_DETAILS';
export const SET_REVENUE_TARGET = 'SET_REVENUE_TARGET';
export const SET_PRODUCTS_OFFER = 'SET_PRODUCTS_OFFER';
export const SET_TERMS_CONDITIONS = 'SET_TERMS_CONDITIONS';
export const UPDATE_STEP_COMPLETED = 'UPDATE_STEP_COMPLETED';
export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';

// all reducers for grid context
export const ContextReducers = (state: ContextState, action: ContextActions) => {
  switch (action.type) {
    case SET_PREV_BUTTON_PROPS:
      return { ...state, prevButtonProps: action.payload };
    case SET_NEXT_BUTTON_PROPS:
      return { ...state, nextButtonProps: action.payload };
    case SET_COMPANY_INFORMATION:
      return { ...state, companyInformation: action.payload };
    case SET_MORE_COMPANY_DETAILS:
      return { ...state, moreCompanyDetails: action.payload };
    case SET_REVENUE_TARGET:
      return { ...state, revenueTarget: action.payload };
    case SET_PRODUCTS_OFFER:
      return { ...state, productsOffer: action.payload };
    case UPDATE_STEP_COMPLETED:
      return { ...state, stepsCompleted: action.payload };
    case UPDATE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
