import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { InputFieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { RevenueTarget } from '../../../../common/interfaces/partnerOnboarding/RevenueTarget';
import { TngGrid } from '../../../../components/common';
import FieldRendering from '../../../../components/CustomFormFields/FieldRendering';
import { RootState } from '../../../../store';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../../context/ContextProvider';
import { ContextActionEnum } from '../../enums/ContextActionEnums';
import { useUpdateDetailsForOnboardedPartner } from '../../hooks/useUpdateDetailsForOnboardedPartner';
import { mapContextDataForUpdate } from '../../utils/mapContextDataForUpdate';
import { initializeRevenueTargetForm } from '../helper/initializeRevenueTargetForm';
import { GetInputFieldConfig } from '../helper/inputFieldConfig';
import { FieldConfig, RevenueTargetFormFields } from '../interface';

interface RevenueTargetFormProps {
  bottomPanelRef?: React.RefObject<BottomPanelRef>; // Add this line to accept bottomPanelRef
}

interface BottomPanelRef {
  setNextButtonState: (state: boolean) => void;
  setLoadingState: (state: boolean) => void;
}

const RevenueTargetForm = forwardRef<unknown, RevenueTargetFormProps>(({ bottomPanelRef }, ref) => {
  const { leadId } = useSelector((state: RootState) => state.partner);
  const partnerOnboardingDispatch = usePartnerOnboardingDispatch();
  const {
    companyInformation,
    productsOffer,
    revenueTarget,
    termsConditions,
    stepsCompleted,
    moreCompanyDetails,
    activeStep,
  } = usePartnerOnboardingState();

  const fieldConfig: FieldConfig[] = GetInputFieldConfig();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm<RevenueTargetFormFields>({
    defaultValues: initializeRevenueTargetForm(revenueTarget),
  });

  const { updateNewPartnerDetails, loading } = useUpdateDetailsForOnboardedPartner();

  const onSubmit: SubmitHandler<RevenueTargetFormFields> = async (
    data: RevenueTargetFormFields,
  ) => {
    try {
      const { year1, year2, year3, businessProposition } = data;
      const revenue: RevenueTarget = {
        year1,
        year2,
        year3,
        businessProposition,
      };
      const revenueTargetFormData = mapContextDataForUpdate(
        companyInformation,
        moreCompanyDetails,
        productsOffer,
        revenue,
        termsConditions,
        stepsCompleted,
        activeStep,
      );

      const { success, error } = await updateNewPartnerDetails(
        revenueTargetFormData,
        leadId ?? null,
        isDirty,
      );
      if (success) {
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_REVENUE_TARGET,
          payload: revenue,
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.UPDATE_ACTIVE_STEP,
          payload: activeStep + 1,
        });
        const isUpdating = activeStep < stepsCompleted;
        if (!isUpdating) {
          partnerOnboardingDispatch({
            type: ContextActionEnum.UPDATE_STEP_COMPLETED,
            payload: stepsCompleted + 1,
          });
        }
      } else {
        throw new Error(error);
      }
    } catch (error: any) {
      /* empty */
    }
  };

  useEffect(() => {
    if (isValid) {
      bottomPanelRef?.current?.setNextButtonState(false);
    } else {
      bottomPanelRef?.current?.setNextButtonState(true);
    }
  }, [isValid, bottomPanelRef]);

  useEffect(() => {
    if (loading) {
      bottomPanelRef?.current?.setLoadingState(true);
    } else {
      bottomPanelRef?.current?.setLoadingState(false);
    }
  }, [bottomPanelRef, loading]);

  // Expose the `onSubmit` handler to the parent using `useImperativeHandle`.
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)(); // Trigger form submission
    },
  }));

  return (
    <TngGrid className="revenue_target_form_container">
      <form aria-label="Revenue Target" className="revenue_target_details_form">
        <TngGrid className="revenue_target_form_fields">
          {fieldConfig?.map((fieldRow: InputFieldConfig[], rowIndex: number) => (
            <TngGrid className="form_row" key={rowIndex}>
              <FieldRendering
                fieldRow={fieldRow}
                errors={errors}
                register={register}
                control={control}
              />
            </TngGrid>
          ))}
        </TngGrid>
      </form>
    </TngGrid>
  );
});

RevenueTargetForm.displayName = 'RevenueTargetForm';

export default RevenueTargetForm;
