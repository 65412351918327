import React from 'react';

import Translations from '../../../../../../assets/locale/en/content.json';

// validate the input text
export const ValidateSearchText = (
  searchText: string,
  setIsError: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorHelperText: React.Dispatch<React.SetStateAction<string>>,
  searchCharacterLimit: number,
): boolean => {
  // check if length > 3
  if (searchText.length < searchCharacterLimit) {
    const errorMessage =
      searchCharacterLimit === 1
        ? Translations.TngGrid.searchbarErrorForSingleChar
        : Translations.TngGrid.searchbarErrorForMultipleChar;
    const errorHelperText = getTranslationWithVariables(errorMessage, {
      searchCharacterLimit,
    });
    setErrorHelperText(errorHelperText);
    setIsError(true);
    return false;
  }
  setIsError(false);
  return true;
};

const getTranslationWithVariables = (
  translation: string,
  variables: Record<string, string | number>,
): string => translation.replace(/{(\w+)}/g, (_, key) => variables[key]?.toString() || '');
