import { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateDetailsForOnboardedPartner } from '../../../api/services/partnerOnboarding/partnerOnboardingService';
import Translations from '../../../assets/locale/en/content.json';
import { useToast } from '../../../components/Toasts/ToastContext';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../context/ContextProvider';
import { ContextActionEnum } from '../enums/ContextActionEnums';
import { UpdateDetailsFormModel } from '../interfaces/updateDetailsFormModel';

export const useUpdateDetailsForOnboardedPartner = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = usePartnerOnboardingDispatch();
  const { activeStep } = usePartnerOnboardingState();

  // toast hook to display toast messages
  const { showToast } = useToast();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      details,
      leadId,
    }: {
      details: UpdateDetailsFormModel;
      leadId: string;
    }) => {
      setLoading(true);
      return await updateDetailsForOnboardedPartner(details, leadId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['updatedetails'] });
      setLoading(false);
    },
    onError: () => {
      // display toast for error
      showToast(Translations.common.formErrorMessage, {
        type: 'error',
      });
    },
  });

  const updateNewPartnerDetails = useCallback(
    async (details: UpdateDetailsFormModel, leadId: string | null, isEnabled: boolean = true) => {
      try {
        if (isEnabled && leadId) {
          await mutation.mutateAsync({ details, leadId });
          return { success: true };
        } else {
          dispatch({ type: ContextActionEnum.UPDATE_ACTIVE_STEP, payload: activeStep + 1 });
          return { success: false };
        }
      } catch (err: any) {
        return { success: false, error: err.message };
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    },
    [mutation],
  );

  return { updateNewPartnerDetails, loading };
};
