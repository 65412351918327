import React, { createContext, useContext, ReactNode, useReducer, Dispatch } from 'react';

import { ContextReducers } from './ContextReducers';
import { ContextActions } from '../interfaces/contextAction';
import {
  CompanyInformation,
  ContextState,
  MoreCompanyDetails,
  ProductsOffer,
  RevenueTarget,
  TermsConditions,
} from '../interfaces/contextState';

export interface PartnerOnboardingContextType {
  state: ContextState;
  dispatch: Dispatch<ContextActions>;
}

// Initial state factory function to support multiple configurations
export const initialState = (): ContextState => ({
  prevButtonProps: {},
  nextButtonProps: {},
  companyInformation: {} as CompanyInformation,
  moreCompanyDetails: {} as MoreCompanyDetails,
  revenueTarget: {} as RevenueTarget,
  productsOffer: {} as ProductsOffer,
  termsConditions: {
    isAgreeTermsAndCondition: false,
    isRequestMarketing: false,
  } as TermsConditions,
  stepsCompleted: 0,
  activeStep: 0,
});

interface PartnerOnboardingProviderProps {
  children: ReactNode | ReactNode[];
}

// Creating context with an undefined default value
export const DataContext = createContext<PartnerOnboardingContextType | undefined>(undefined);

export const PartnerOnboardingContextProvider: React.FC<PartnerOnboardingProviderProps> = ({
  children,
}) => {
  // State to manage the Partner onboarding context
  const [state, dispatch] = useReducer(ContextReducers, initialState());

  return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>;
};

// method to get state
export const usePartnerOnboardingState = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('usePartnerOnboardingState must be used within PartnerOnboarding Provider');
  }
  return context.state;
};

// method to get dispatch function
export const usePartnerOnboardingDispatch = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('usePartnerOnboardingDispatch must be used within PartnerOnboarding Provider');
  }
  return context.dispatch;
};
