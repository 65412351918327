import Translations from '../../../../assets/locale/en/content.json';
import { CountryOptions } from '../../../../common/interfaces/CountryOptions';
import { FieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { StateOptions } from '../../../../common/interfaces/StateOptions';

/**
Create a field config for form to render fields on UI
@parameter {countryOptions} - List of options for country dropdown.
@parameter {stateOptions} - List of options for state dropdown.
@returns FieldConfig[] - return field config
*/

export const GetInputFieldConfig = (
  countryOptions: CountryOptions[],
  stateOptions: StateOptions[],
  selectedCountry: number | null,
): FieldConfig[] => {
  const country = countryOptions.filter(
    (country) => country.value === Translations.common.usCountry,
  );
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.partnerOnboarding.companyName,
        htmlFor: 'companyName',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.companyName,
        validationRules: { required: true, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.websiteURL,
        htmlFor: 'websiteURL',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.websiteURL,
        validationRules: { required: true, maxLength: 100 },
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.country,
        htmlFor: 'country',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: { required: true },
        options: countryOptions,
      },
      {
        label: Translations.partnerOnboarding.provieceState,
        htmlFor: 'state',
        fieldType: 'dropdown',
        placeholder: 'Select',
        validationRules: {
          required: country[0]?.id === selectedCountry,
          disabled: selectedCountry ? false : true,
        },
        options: stateOptions,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.city,
        htmlFor: 'city',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.city,
        validationRules: { required: true, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.street,
        htmlFor: 'street',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.street,
        validationRules: { required: true, maxLength: 255 },
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.postalZipCode,
        htmlFor: 'postalCode',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.postalZipCode,
        validationRules: { required: false, maxLength: 20 },
        layoutRatio: 2,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.phoneNumber,
        htmlFor: 'phoneNumber',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.phoneNumber,
        validationRules: { required: true, maxLength: 40 },
      },
      {
        label: Translations.partnerOnboarding.fax,
        htmlFor: 'fax',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.fax,
        validationRules: { required: false, maxLength: 40 },
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.yourJobTitle,
        htmlFor: 'jobTitle',
        fieldType: 'text',
        placeholder: Translations.partnerOnboarding.yourJobTitle,
        validationRules: { required: true, maxLength: 40 },
      },
    ],
  ];
  return fieldConfig;
};
