/**
Initialize the react hook form with default/empty value
@returns terms - object of terms and condition form fields
*/
import { TermsAndConditions } from '../interface';

export const initializeTermsForm = () => {
  const defaultValues: TermsAndConditions = {
    isAgreeTermsAndCondition: false,
    isRequestMarketing: false,
  };
  return defaultValues;
};
