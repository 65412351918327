import React from 'react';

import { Control, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import CheckboxField from './CheckboxField';
import ChipField from './ChipField';
import DropdownField from './DropdownField';
import InputField from './InputField';
import PasswordField from './PasswordField';
import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';

interface FieldRenderingProps<TFieldRow, TFormField extends FieldValues> {
  fieldRow: TFieldRow;
  errors: FieldErrors<TFormField>;
  register: UseFormRegister<TFormField>;
  control: Control<TFormField>;
  dropdownCallback?: (field: InputFieldConfig, e: any) => void;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FieldRendering = <TFieldRow extends InputFieldConfig[], TFormField extends FieldValues>({
  fieldRow,
  register,
  errors,
  control,
  dropdownCallback,
  showPassword,
  setShowPassword,
}: FieldRenderingProps<TFieldRow, TFormField>) => {
  return (
    <>
      {fieldRow.map((field: InputFieldConfig) => {
        switch (field.fieldType) {
          case 'text':
          case 'number':
          case 'textarea':
            return (
              <InputField key={field.htmlFor} field={field} register={register} errors={errors} />
            );
          case 'dropdown':
            return (
              <DropdownField
                key={field.htmlFor}
                field={field}
                errors={errors}
                control={control}
                dropdownCallback={dropdownCallback}
              />
            );
          case 'password':
            return (
              <PasswordField
                key={field.htmlFor}
                field={field}
                errors={errors}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                register={register}
              />
            );
          case 'chip':
            return (
              <ChipField
                key={field.htmlFor}
                field={field}
                register={register}
                errors={errors}
                control={control}
              />
            );
          case 'checkbox':
            return (
              <CheckboxField key={field.htmlFor} field={field} errors={errors} control={control} />
            );
          default:
            return null; // Return null if no matching case is found
        }
      })}
    </>
  );
};

export default FieldRendering;
