import React, { useEffect, useRef } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { RenderMenuLinks } from './RenderMenuLinks';
import { TngGrid, TngList } from '../../../../common';
import { MenuItemModal } from '../modal/MenuItemModal';

interface SideMenuListProps {
  item: MenuItemModal[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: string | null;
  setExpanded: React.Dispatch<React.SetStateAction<string | null>>;
  focusedItem: string | null;
  setFocusedItem: React.Dispatch<React.SetStateAction<string | null>>;
}

const SideMenuList: React.FC<SideMenuListProps> = ({
  item,
  open,
  setOpen,
  expanded,
  setExpanded,
  focusedItem,
  setFocusedItem,
}) => {
  const location = useLocation();
  const summaryRefs = useRef(new Map<string, HTMLDivElement>());

  const handleAccordionToggle = (itemName: string, hasChildItems: boolean, isParent: boolean) => {
    setFocusedItem(itemName);
    if (hasChildItems) {
      if (!open) setOpen(true);
      setExpanded(expanded === itemName ? null : itemName);
    } else {
      if (isParent) {
        setExpanded(null);
      }
    }
  };

  const isLinkActive = (activeItems: string[]) => {
    const segments = location.pathname.split('/');
    const lastSegment = segments[segments.length - 1];
    return activeItems.includes(`/${lastSegment}`);
  };

  const RenderItems = (items: MenuItemModal[]) => {
    return items.map((item) => {
      const hasChildItems = item.items && item.items.length > 0;

      return (
        <TngGrid
          key={item.itemName}
          className={`side_menu_list_item  ${!open ? 'side_menu_close' : ''} ${(isLinkActive(item.activeItems) || expanded === item.itemName) && 'active_panel'}`}
        >
          {/* Render Accordion regardless of child existence */}
          <Accordion
            expanded={hasChildItems && expanded === item.itemName} // Only expand if it has children
            onChange={() => handleAccordionToggle(item.itemName, hasChildItems, item.isParent)}
            className="side_menu_accordian"
            aria-labelledby={`accordion-summary-${item.itemName}`}
            aria-expanded={expanded === item.itemName}
          >
            <AccordionSummary
              role={hasChildItems ? 'menuitem' : undefined}
              expandIcon={hasChildItems && <ExpandMoreIcon />} // No expand icon if no children
              className="accordian_summary"
              id={`accordion-summary-${item.itemName}`}
              aria-label={item.itemName}
              ref={(el) => {
                if (el) summaryRefs.current.set(item.itemName, el);
              }}
            >
              <RenderMenuLinks item={item} />
            </AccordionSummary>
            {hasChildItems && (
              <AccordionDetails
                className="accordian_details"
                id={`accordion-details-${item.itemName}`}
              >
                <TngList className="side_menu_list_item_child">{RenderItems(item.items)}</TngList>
              </AccordionDetails>
            )}
          </Accordion>
        </TngGrid>
      );
    });
  };

  // Effect to handle focus when accordion is expanded/collapsed
  useEffect(() => {
    if (focusedItem && summaryRefs.current.has(focusedItem)) {
      const ref = summaryRefs.current.get(focusedItem);
      if (ref) {
        ref.focus(); // Focus the AccordionSummary when expanded
      }
    }
  }, [focusedItem]);

  return <TngList className="side_menu_list">{RenderItems(item)}</TngList>;
};

export default SideMenuList;
