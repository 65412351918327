import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckboxProps {
  keyId: number | string;
  checked: boolean;
  ariaLabel: string;
  handleToggleCheckbox: (value: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  [x: string]: any;
}

export const TngCheckbox: React.FC<CheckboxProps> = ({
  keyId,
  checked,
  ariaLabel,
  handleToggleCheckbox,
  label,
  ...rest
}) => {
  return (
    <FormControlLabel
      sx={{ display: 'block' }}
      key={keyId}
      control={
        <Checkbox
          aria-label={ariaLabel}
          checked={checked}
          onChange={handleToggleCheckbox}
          {...rest}
        />
      }
      label={label}
    />
  );
};
