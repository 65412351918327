import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Translations from '../../../assets/locale/en/content.json';
import { useLeadStatus } from '../../../common/hooks/useLeadStatus';
import { TngButton, TngGrid, TngLoader, TngTypography } from '../../../components/common';
import { setPartnerApplication } from '../../../store/partner/partnerReducer';

const UnRegisteredPartnerDashboard = () => {
  const { data, isLoading } = useLeadStatus(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setPartnerApplication({
          leadId: data.id,
          status: data.status,
        }),
      );
    }
  }, [data, dispatch]);

  const handleButtonClick = () => {
    navigate(`/partner-onboarding`);
  };

  if (isLoading) {
    return <TngLoader />;
  }
  return (
    <TngGrid className="unregistered_partner_dashboard">
      <TngTypography className="welcome_text1">
        {!data?.id ? (
          Translations.partnerOnboarding.becomePartnerParagraph1
        ) : (
          <>
            {Translations.partnerOnboarding.resumePartnerParagraph1}
            <TngTypography component="strong">
              &quot;{Translations.partnerOnboarding.resumeApplication}&quot;
            </TngTypography>
            {Translations.partnerOnboarding.resumePartnerParagraph2}
          </>
        )}
      </TngTypography>
      {!data?.id && (
        <TngTypography className="welcome_text2">
          {Translations.partnerOnboarding.becomePartnerParagraph2}
          <TngTypography component="strong">
            &quot;{Translations.partnerOnboarding.becomePartner}&quot;
          </TngTypography>
          {Translations.partnerOnboarding.becomePartnerParagraph3}
        </TngTypography>
      )}
      <TngGrid className="partner_application_grid">
        <TngTypography variant="h5" className="partner_application_header">
          {Translations.partnerOnboarding.tungstenAutomationPartnerApplication}
        </TngTypography>
        <TngTypography className="partner_application_text">
          {Translations.partnerOnboarding.belowToInitiate}
        </TngTypography>
        <TngButton className="partner_application_btn" onClick={handleButtonClick}>
          {!data?.id
            ? Translations.partnerOnboarding.becomePartner
            : Translations.partnerOnboarding.resumeApplication}
        </TngButton>
      </TngGrid>
    </TngGrid>
  );
};

export default UnRegisteredPartnerDashboard;
