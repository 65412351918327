/**
Initialize the react hook form with default/empty value
@returns products - object of Product Offers form fields
*/
import { ProductOffer } from '../interface';

export const initializeProductsOfferForm = (products: ProductOffer) => {
  const defaultValues: ProductOffer = {
    workflowAutomationProducts: products?.workflowAutomationProducts || [],
    invoiceAutomationProducts: products?.invoiceAutomationProducts || [],
    documentAutomationProducts: products?.documentAutomationProducts || [],
  };
  return defaultValues;
};
