import React, { useEffect, useState } from 'react';

import TngSearchbar from './TngSearchbar';
import TngSearchDropdown from './TngSearchDropdown';
import { useGridState } from '../../../../../common/GridContext/GridContextProvider';
import { GridSearchConfig } from '../../../../../common/interfaces/GridSearchConfig';
import { TngGrid } from '../../../../common';

interface TngSearchByProps {
  searchConfig: GridSearchConfig;
}

const TngSearchBy: React.FC<TngSearchByProps> = ({ searchConfig }) => {
  const { search } = useGridState();
  const [searchBy, setSearchBy] = useState<string>(search.searchBy);
  const [searchValue, setSearchValue] = useState<string>(search.searchValue);

  useEffect(() => {
    setSearchBy(search.searchBy);
    setSearchValue(search.searchValue);
  }, [search.searchBy, search.searchValue]);
  return (
    <TngGrid container className="grid_searchby_container">
      {searchConfig.isDropdownApplicable && searchConfig.searchDropdownOptions && (
        <TngGrid item>
          <TngSearchDropdown
            dropdownOptions={searchConfig.searchDropdownOptions}
            searchBy={searchBy}
            setSearchBy={setSearchBy}
          />
        </TngGrid>
      )}
      <TngGrid item>
        <TngSearchbar
          isDropdownApplicable={searchConfig.isDropdownApplicable ?? false}
          searchBy={searchBy}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={searchConfig.placeholder ?? ''}
          searchCharacterLimit={searchConfig?.characterLimit ?? 3}
        />
      </TngGrid>
    </TngGrid>
  );
};

export default TngSearchBy;
