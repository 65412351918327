import { TransformDate } from '../../../../../utils/dateTimeConvertor';
import { ProductGridModal } from '../modal/ProductGridModal';
import { ProductListModal } from '../modal/ProductListModal';

// to map the data according to grid columns
export const mapProductDetailsToProductGridModal = (
  products: ProductListModal[],
): ProductGridModal[] => {
  if (products.length === 0) return [];
  return products.map((product) => ({
    productName: product.productName,
    itemNumber: product.itemNumber,
    quantity: product.quantity,
    unitPrice: priceWithCurrencyCode(product.unitPrice, product.currencyCode),
    totalPrice: priceWithCurrencyCode(product.totalPrice, product.currencyCode),
    startDate: TransformDate(product.startDate),
    endDate: TransformDate(product.endDate),
    salesLevelAgreement: product.salesLevelAgreement,
  }));
};

const priceWithCurrencyCode = (price: string, code: string) => {
  return price + ' ' + code;
};
