// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserTypesEmuns } from '../../common/enums/UserTypesEmuns';
import { getUserInfoFromToken } from '../../utils/getUserTypeFromToken';
import isTokenValid from '../../utils/jwtTokenValidation';

interface AuthState {
  userRole: UserTypesEmuns | null;
  token: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

// Retrieve and parse the tokens object from localStorage
const storedTokens = JSON.parse(localStorage.getItem('tokens') || '{}');

// Access individual tokens
const token = storedTokens?.token;
const refreshToken = storedTokens?.refreshToken;
const userInfo = getUserInfoFromToken(token);
// Currently using a temporary username for test purposes. Will remove later
const initialState: AuthState = {
  token: token, // Rehydrate token from localStorage
  refreshToken: refreshToken, // Rehydrate refreshToken from localStorage
  isAuthenticated: token ? isTokenValid(token) : false,
  userRole: token && userInfo?.userType,
  firstName: token && userInfo?.givenName,
  lastName: token && userInfo?.familyName,
  email: token && userInfo?.userName,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(
      state,
      { payload: { token, refreshToken } }: PayloadAction<{ token: string; refreshToken: string }>,
    ) {
      state.token = token;
      state.isAuthenticated = true;
      state.refreshToken = refreshToken;
      const userInfo = getUserInfoFromToken(token);
      // decrypt user info from token and store it on redux store
      state.userRole = userInfo?.userType || null;
      state.firstName = userInfo?.givenName || null;
      state.lastName = userInfo?.familyName || null;
      state.email = userInfo?.userName || null;
      // Store token and refreshToken in localStorage
      const tokens = { token: token, refreshToken: refreshToken };
      // Store the entire object in localStorage as a JSON string
      localStorage.setItem('tokens', JSON.stringify(tokens));
    },
    logout(state) {
      state.userRole = null;
      state.token = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      // Clear tokens and user data from local storage
      localStorage.removeItem('tokens');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
