import React, { forwardRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import RevenueTargetForm from './components/RevenueTargetForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

interface RevenueTargetProps {
  bottomPanelRef: any;
}

const RevenueTarget = forwardRef<HTMLDivElement, RevenueTargetProps>(({ bottomPanelRef }, ref) => {
  useEffect(() => {
    const mainDiv = document.getElementById('stepper_component_container');
    focusFirstInteractiveElement(mainDiv);
  }, []);
  return (
    <TngGrid className="stepper_form_container">
      <TngGrid className="stepper_form_container_header_container">
        <Typography
          variant="h4"
          className="stepper_form_container_header"
          aria-label={Translations.ariaLabels.revenueTarget}
        >
          3. {Translations.partnerOnboarding.revenueTarget}
        </Typography>
        <Typography className="stepper_form_container_helper_text">
          {Translations.partnerOnboarding.formRequiredFieldText}
        </Typography>
      </TngGrid>
      <TngGrid className="stepper_form_helper_text">
        {Translations.partnerOnboarding.revenueTargetRequiredFieldText}
      </TngGrid>
      <RevenueTargetForm ref={ref} bottomPanelRef={bottomPanelRef} />
    </TngGrid>
  );
});

RevenueTarget.displayName = 'RevenueTarget';

export default RevenueTarget;
