import React, { forwardRef } from 'react';

import { Typography } from '@mui/material';

import MoreCompanyDetailsForm from './components/MoreCompanyDetailsForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';

interface MoreCompanyDetailsProps {
  bottomPanelRef: any;
}

const MoreCompanyDetails = forwardRef<HTMLDivElement, MoreCompanyDetailsProps>(
  ({ bottomPanelRef }, ref) => {
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <Typography variant="h4" className="stepper_form_container_header">
            2. {Translations.partnerOnboarding.moreCompanyDetails}
          </Typography>
          <Typography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </Typography>
        </TngGrid>
        <MoreCompanyDetailsForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

MoreCompanyDetails.displayName = 'MoreCompanyDetails';

export default MoreCompanyDetails;
