import React from 'react';

import { useSelector } from 'react-redux';

import RegisteredPartnerDashboard from './components/RegisteredPartnerDashboard';
import UnRegisteredPartnerDashboard from './components/UnRegisteredPartnerDashboard';
import Translations from '../../assets/locale/en/content.json';
import { UserTypesEmuns } from '../../common/enums/UserTypesEmuns';
import { TngGrid, TngTypography } from '../../components/common';
import { RootState } from '../../store';

const Dashboard = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const userState = useSelector((state: RootState) => state.user.user);

  const isTemporaryUser = authState.userRole === UserTypesEmuns.PTU;
  const { firstName } = isTemporaryUser ? authState : userState;

  return (
    <TngGrid container className="dashboard_parent_container">
      <TngGrid container className="dashboard_child_container">
        <TngGrid item xs={12} className="dashboard_main_heading">
          <TngTypography>{`${Translations.dashboard.welcomeGreeting}, ${firstName || ''}`}</TngTypography>
        </TngGrid>
        {isTemporaryUser ? <UnRegisteredPartnerDashboard /> : <RegisteredPartnerDashboard />}
      </TngGrid>
    </TngGrid>
  );
};

export default Dashboard;
