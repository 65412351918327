import {
  CompanyInformation,
  MoreCompanyDetails,
  ProductsOffer,
  RevenueTarget,
  TermsConditions,
} from '../interfaces/contextState';
import { UpdateDetailsFormModel } from '../interfaces/updateDetailsFormModel';

export const mapContextDataForUpdate = (
  companyInformation: CompanyInformation,
  moreCompanyDetails: MoreCompanyDetails,
  productsOffer: ProductsOffer,
  revenueTarget: RevenueTarget,
  termsConditions: TermsConditions,
  stepsCompleted: number,
  activeStep: number,
) => {
  const mappedData: UpdateDetailsFormModel = {
    companyName: companyInformation.companyName,
    websiteURL: companyInformation.websiteURL,
    firstName: companyInformation.firstName,
    lastName: companyInformation.lastName,
    jobTitle: companyInformation.jobTitle,
    fax: companyInformation.fax,
    phoneNumber: companyInformation.phoneNumber,
    stepsCompleted: activeStep < stepsCompleted ? stepsCompleted : stepsCompleted + 1,
    emailAddress: companyInformation.emailAddress,
    address: {
      city: companyInformation.city,
      street: companyInformation.street,
      country: companyInformation.countryCode,
      postalCode: companyInformation.postalCode,
      state: companyInformation.stateCode,
      countryCode: companyInformation.countryCode,
      stateCode: companyInformation.stateCode,
    },
    typeOfCompany: moreCompanyDetails.typeOfCompany ?? null,
    primaryTypeOfBusiness: moreCompanyDetails.primaryTypeOfBusiness ?? null,
    geographicAreaServed: moreCompanyDetails.geographicAreaServed ?? null,
    lengthInTimeInBusiness: moreCompanyDetails.lengthInTimeInBusiness ?? null,
    targetMarkets: moreCompanyDetails.targetMarkets ?? null,
    numberOfEmployees: Number(moreCompanyDetails.numberOfEmployees) ?? null,
    isSellingThroughOtherResellers: moreCompanyDetails.isSellingThroughOtherResellers ?? null,
    revenueTarget: {
      year1: revenueTarget.year1 ?? null,
      year2: revenueTarget.year2 ?? null,
      year3: revenueTarget.year3 ?? null,
    },
    businessProposition: revenueTarget.businessProposition ?? null,
    potentialProductOffered: {
      documentAutomationProducts:
        productsOffer.documentAutomationProducts?.length > 0
          ? productsOffer.documentAutomationProducts
          : null,
      invoiceAutomationProducts:
        productsOffer.invoiceAutomationProducts?.length > 0
          ? productsOffer.invoiceAutomationProducts
          : null,
      workflowAutomationProducts:
        productsOffer.workflowAutomationProducts?.length > 0
          ? productsOffer.workflowAutomationProducts
          : null,
    },
    isAgreeTermsAndCondition: termsConditions.isAgreeTermsAndCondition,
    isRequestMarketing: termsConditions.isRequestMarketing,
  };

  return mappedData;
};
