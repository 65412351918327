import { useQuery } from '@tanstack/react-query';

import { fetchLeadStatus } from '../../api/services/partnerOnboarding/partnerOnboardingService';
import { LeadStatusResponse } from '../interfaces/lead/leadStatus';

/**
Custom hook to fetch the order statuses count using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useLeadStatus = (lead: string | null) => {
  const { data, error, isLoading } = useQuery<LeadStatusResponse, Error>({
    queryKey: ['leadStatus'],
    queryFn: fetchLeadStatus,
    enabled: !lead,
  });
  return { data, error, isLoading };
};
