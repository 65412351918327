import { useSelector } from 'react-redux';

import ActiveUserIcon from '../assets/icons/ActiveUserIcon';
import CommunityIcon from '../assets/icons/CommunityIcon';
import DashboardIcon from '../assets/icons/DashboardIcon';
import DemoCenterIcon from '../assets/icons/DemoCenterIcon';
import EvalIcon from '../assets/icons/EvalIcon';
import KnowledgeCenterIcon from '../assets/icons/KnowledgeCenterIcon';
import ManageCustomerIcon from '../assets/icons/ManageCustomerIcon';
import ManageOpportunitiesIcon from '../assets/icons/ManageOpportunitiesIcon';
import ManageProspectsIcon from '../assets/icons/ManageProspectsIcon';
import MarketingIcon from '../assets/icons/MarketingIcon';
import MarketPlaceIcon from '../assets/icons/MarketPlaceIcon';
import NewSupportRequestIcon from '../assets/icons/NewSalesSupportIcon';
import OrderIcon from '../assets/icons/OrderIcon';
import PartnerProgramGuideIcon from '../assets/icons/PartnerProgramGuideIcon';
import PendingLeadsWAIVAIcon from '../assets/icons/PendingLeadsWAIVAIcon';
import PriceListIcon from '../assets/icons/PriceListIcon';
import RegisterNewLeadPPDFIcon from '../assets/icons/RegisterNewLeadPPDFIcon';
import RegisterNewLeadWAIVAIcon from '../assets/icons/RegisterNewLeadWAIVAIcon';
import SalesIcon from '../assets/icons/SalesIcon';
import SalesResourceIcon from '../assets/icons/SalesResourceIcon';
import SupportIcon from '../assets/icons/SupportIcon';
import TechnicalCertificationIcon from '../assets/icons/TechnicalCertificationIcon';
import TechnicalCommunityIcon from '../assets/icons/TechnicalCommunityIcon';
import TechnicalEducationIcon from '../assets/icons/TechnicalEducationIcon';
import TechSupportIcon from '../assets/icons/TechSupportIcon';
import UserManagementIcon from '../assets/icons/UserManagementIcon';
import ViewSupportCasesIcon from '../assets/icons/ViewSupportCasesIcon';
import { IconMapModal } from '../common/interfaces/IconMapModal';
import { Icons } from '../common/interfaces/userAdmin/Icons';
import { RootState } from '../store';

// define all icons with key
const iconMap: IconMapModal = {
  DashboardIcon: DashboardIcon,
  OrderIcon: OrderIcon,
  ActiveUserIcon: ActiveUserIcon,
  KnowledgeCenterIcon: KnowledgeCenterIcon,
  MarketingIcon: MarketingIcon,
  PartnerProgramGuideIcon: PartnerProgramGuideIcon,
  SalesIcon: SalesIcon,
  EvalIcon: EvalIcon,
  DemoCenterIcon: DemoCenterIcon,
  PriceListIcon: PriceListIcon,
  SalesResourceIcon: SalesResourceIcon,
  ManageCustomerIcon: ManageCustomerIcon,
  CommunityIcon: CommunityIcon,
  MarketPlaceIcon: MarketPlaceIcon,
  TechnicalCommunityIcon: TechnicalCommunityIcon,
  TechnicalEducationIcon: TechnicalEducationIcon,
  TechnicalCertificationIcon: TechnicalCertificationIcon,
  TechSupportIcon: TechSupportIcon,
  SupportIcon: SupportIcon,
  NewSupportRequestIcon: NewSupportRequestIcon,
  ViewSupportCasesIcon: ViewSupportCasesIcon,
  UserManagementIcon: UserManagementIcon,
  ManageProspectsIcon: ManageProspectsIcon,
  ManageOpportunitiesIcon: ManageOpportunitiesIcon,
  RegisterNewLeadPPDFIcon: RegisterNewLeadPPDFIcon,
  RegisterNewLeadWAIVAIcon: RegisterNewLeadWAIVAIcon,
  PendingLeadsWAIVAIcon: PendingLeadsWAIVAIcon,
};

export const useGetIconByText = (iconId: number) => {
  const { icons } = useSelector((state: RootState) => state.master);
  const icon = icons?.find((icon: Icons) => icon.id === iconId)?.name;
  return icon ? iconMap[icon] : null;
};
