// Define Content Action types
export enum ContextActionEnum {
  SET_PREV_BUTTON_PROPS = 'SET_PREV_BUTTON_PROPS',
  SET_NEXT_BUTTON_PROPS = 'SET_NEXT_BUTTON_PROPS',
  SET_COMPANY_INFORMATION = 'SET_COMPANY_INFORMATION',
  SET_MORE_COMPANY_DETAILS = 'SET_MORE_COMPANY_DETAILS',
  SET_REVENUE_TARGET = 'SET_REVENUE_TARGET',
  SET_PRODUCTS_OFFER = 'SET_PRODUCTS_OFFER',
  SET_TERMS_CONDITIONS = 'SET_TERMS_CONDITIONS',
  UPDATE_STEP_COMPLETED = 'UPDATE_STEP_COMPLETED',
  UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP',
}
