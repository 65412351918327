import React, { ReactNode } from 'react';

import { TngGrid } from '../../common';
import Navbar from '../DashboardLayout/Navbar';

interface HeaderLayoutProps {
  children: ReactNode;
  isContentVisible?: boolean;
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children, isContentVisible }) => {
  return (
    <TngGrid className="layout_container">
      <Navbar isContentVisible={isContentVisible} />
      <TngGrid className="dashboard_container">{children}</TngGrid>
    </TngGrid>
  );
};

export default React.memo(HeaderLayout);
