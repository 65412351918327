import React, { useState } from 'react';

import Translations from '../../../../assets/locale/en/content.json';
import { useOpportunityList } from '../../../../common/hooks/useOpportunityList';
import { OpportunityModel } from '../../../../common/interfaces/quotes/OppertunityDetailsResponse';
import { ErrorProps } from '../../../../common/interfaces/SearchDropdown';
import { TngGrid } from '../../../../components/common';
import TngMultiSelectSearchDropdown from '../../../../components/common/TngMultiSelectSearchDropdown';
import { ManageQuotesFilter } from '../../modals/ManageQuotesFilter';

interface OpportunityDropdownProps {
  filterObj: ManageQuotesFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<ManageQuotesFilter>>;
}

const OpportunityDropdown: React.FC<OpportunityDropdownProps> = ({ filterObj, setFiltersObj }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [opportunities, setOpportunities] = useState<OpportunityModel[]>([]);
  const [error, setError] = useState<ErrorProps>({
    isError: false,
    message: '',
  });
  const { getOpportunityList } = useOpportunityList();

  // filtered out data/options according to selected user type
  const filterOptions: any = () => {
    return opportunities.map((cus: OpportunityModel) => {
      return { name: cus.name, id: cus.id };
    });
  };

  const handleSearchButtonClick = async () => {
    if (searchValue.trim().length >= 3) {
      const opportunities = await getOpportunityList(searchValue);
      if (opportunities.length === 0)
        setError({ isError: true, message: Translations.filterSection.errorMessage });
      else setError({ isError: false });

      setOpportunities(opportunities);
    } else {
      setError({ isError: true, message: Translations.filterSection.minSearchValueText });
    }
  };

  return (
    <TngGrid container marginTop="20px">
      <TngMultiSelectSearchDropdown
        dropdownLabel={Translations.filterSection.opportunityName}
        options={filterOptions()}
        selectedOptions={filterObj.opportunityValue.map((opportunity) => opportunity.id)}
        placeholderText={Translations.filterSection.typeOpportunityNamePlaceholder}
        handleSearchButtonClick={handleSearchButtonClick}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setOptionValue={(newSelectedOpportunityValues) => {
          const selectedIds = newSelectedOpportunityValues.map((opportunity) => opportunity.id);
          setFiltersObj({
            ...filterObj,
            opportunities: newSelectedOpportunityValues
              .filter((cus) => selectedIds.includes(cus.id))
              .map((cus) => cus.name),
            opportunityValue: newSelectedOpportunityValues
              .filter((cus) => selectedIds.includes(cus.id))
              .map((cus) => ({ id: cus.id, name: cus.name })),
          });
        }}
        optionValue={filterObj.opportunityValue}
        error={error}
        setError={setError}
        setMaxOptionsLength={9}
      />
    </TngGrid>
  );
};

export default OpportunityDropdown;
