import { PartnerOnboarding } from '../../../common/interfaces/partnerOnboarding/PartnerOnboarding';
import { LeadContextMappedData } from '../interfaces';
import {
  CompanyInformation,
  MoreCompanyDetails,
  ProductsOffer,
  RevenueTarget,
} from '../interfaces/contextState';

export const mapLeadDataToContext = (lead: PartnerOnboarding): LeadContextMappedData => {
  const {
    companyName,
    firstName,
    lastName,
    emailAddress,
    websiteURL,
    phoneNumber,
    fax,
    jobTitle,
    address,
    typeOfCompany,
    primaryTypeOfBusiness,
    geographicAreaServed,
    lengthInTimeInBusiness,
    targetMarkets,
    numberOfEmployees,
    isSellingThroughOtherResellers,
    revenueTarget,
    businessProposition,
    potentialProductOffered,
    stepsCompleted,
  } = lead;

  // Map `CompanyInformation`
  const companyInformation: CompanyInformation = {
    companyName: companyName,
    websiteURL: websiteURL,
    countryCode: address?.countryCode,
    stateCode: address?.stateCode,
    city: address?.city,
    street: address?.street,
    postalCode: address?.postalCode,
    phoneNumber: phoneNumber,
    fax: fax,
    jobTitle: jobTitle,
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
  };

  // Map `MoreCompanyDetails`
  const moreCompanyDetails: MoreCompanyDetails = {
    typeOfCompany: typeOfCompany,
    primaryTypeOfBusiness: primaryTypeOfBusiness,
    geographicAreaServed: geographicAreaServed,
    lengthInTimeInBusiness: lengthInTimeInBusiness,
    targetMarkets: targetMarkets,
    numberOfEmployees: numberOfEmployees,
    isSellingThroughOtherResellers: isSellingThroughOtherResellers,
  };

  const revenueTargetState: RevenueTarget = {
    year1: revenueTarget?.year1,
    year2: revenueTarget?.year2,
    year3: revenueTarget?.year3,
    businessProposition: businessProposition,
  };

  // Map `ProductsOffer`
  const productsOffer: ProductsOffer = {
    workflowAutomationProducts: potentialProductOffered?.workflowAutomationProducts || [],
    invoiceAutomationProducts: potentialProductOffered?.invoiceAutomationProducts || [],
    documentAutomationProducts: potentialProductOffered?.documentAutomationProducts || [],
  };

  // Return the complete `ContextState`
  return {
    companyInformation,
    moreCompanyDetails,
    revenueTargetState,
    productsOffer,
    stepsCompleted: Number(stepsCompleted),
  };
};
