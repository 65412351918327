import axios from 'axios';

const pingAxiosInstance = axios.create({
  baseURL: `https://auth.pingone.com/${process.env.REACT_APP_ENVIRONMENT_ID}/`,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
  maxRedirects: 10,
  withCredentials: true,
});

export default pingAxiosInstance;
