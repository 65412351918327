import { MoreCompanyDetails } from '../../interfaces/contextState';
import { MoreCompanyDetailsFormFields } from '../interface';

export const initializeCompanyDetailForm = (
  moreCompanyDetails: MoreCompanyDetails,
): MoreCompanyDetailsFormFields => {
  const {
    typeOfCompany,
    primaryTypeOfBusiness,
    geographicAreaServed,
    lengthInTimeInBusiness,
    targetMarkets,
    isSellingThroughOtherResellers,
    numberOfEmployees,
  } = moreCompanyDetails;

  return {
    typeOfCompany: typeOfCompany ? [typeOfCompany] : [],
    primaryTypeOfBusiness: primaryTypeOfBusiness ? [primaryTypeOfBusiness] : [],
    geographicAreaServed: geographicAreaServed ? [geographicAreaServed] : [],
    lengthInTimeInBusiness: lengthInTimeInBusiness ? [lengthInTimeInBusiness] : [],
    targetMarkets: targetMarkets,
    numberOfEmployees: numberOfEmployees,
    isSellingThroughOtherResellers: isSellingThroughOtherResellers ? ['Yes'] : ['No'],
  };
};
