import Translations from '../../../../assets/locale/en/content.json';
import { FieldConfig } from '../interface';
/**
Create a field config for form to render fields on UI
@returns FieldConfig[] - return field config
*/
export const GetInputFieldConfig = (): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.partnerOnboarding.firstYearRevenueTarget,
        htmlFor: 'year1',
        fieldType: 'text',
        validationRules: { required: true, maxLength: 40 },
        placeholder: 'Enter the year 1 revenue target',
        layoutRatio: 2,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.secondYearRevenueTarget,
        htmlFor: 'year2',
        fieldType: 'text',
        validationRules: { required: true, maxLength: 40 },
        placeholder: 'Enter the year 2 revenue target',
        layoutRatio: 2,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.thirdYearRevenueTarget,
        htmlFor: 'year3',
        fieldType: 'text',
        validationRules: { required: true, maxLength: 40 },
        placeholder: 'Enter the year 3 revenue target',
        layoutRatio: 2,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.briefDescription,
        htmlFor: 'businessProposition',
        fieldType: 'textarea',
        validationRules: { required: true, maxLength: 131072 },
        placeholder: 'Enter explaination here',
        rowsize: 12,
      },
    ],
  ];
  return fieldConfig;
};
