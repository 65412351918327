import React from 'react';

const PendingLeadsWAIVAIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_18072_59926)">
        <path
          d="M12.375 9.375C14.446 9.375 16.125 7.69607 16.125 5.625C16.125 3.55393 14.446 1.875 12.375 1.875C10.304 1.875 8.625 3.55393 8.625 5.625C8.625 7.69607 10.304 9.375 12.375 9.375Z"
          stroke="#8094AA"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M12.375 12.375C7.40475 12.375 3.375 16.2556 3.375 21.0419C6.12484 21.7038 8.8749 22.0623 11.625 22.1175"
          stroke="#8094AA"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M18.375 22.875C21.2745 22.875 23.625 20.5245 23.625 17.625C23.625 14.7255 21.2745 12.375 18.375 12.375C15.4755 12.375 13.125 14.7255 13.125 17.625C13.125 20.5245 15.4755 22.875 18.375 22.875Z"
          stroke="#8094AA"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M18.375 15.375V17.625L20.625 19.125"
          stroke="#8094AA"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_18072_59926">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PendingLeadsWAIVAIcon;
