import React, { forwardRef } from 'react';

import { Typography } from '@mui/material';

import RevenueTargetForm from './components/RevenueTargetForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';

interface RevenueTargetProps {
  bottomPanelRef: any;
}

const RevenueTarget = forwardRef<HTMLDivElement, RevenueTargetProps>(({ bottomPanelRef }, ref) => {
  return (
    <TngGrid className="stepper_form_container">
      <TngGrid className="stepper_form_container_header_container">
        <Typography variant="h4" className="stepper_form_container_header">
          3. {Translations.partnerOnboarding.revenueTarget}
        </Typography>
        <Typography className="stepper_form_container_helper_text">
          {Translations.partnerOnboarding.formRequiredFieldText}
        </Typography>
      </TngGrid>
      <TngGrid className="stepper_form_helper_text">
        {Translations.partnerOnboarding.revenueTargetRequiredFieldText}
      </TngGrid>
      <RevenueTargetForm ref={ref} bottomPanelRef={bottomPanelRef} />
    </TngGrid>
  );
});

RevenueTarget.displayName = 'RevenueTarget';

export default RevenueTarget;
