import { useQuery } from '@tanstack/react-query';

import { getGridData } from '../../../../api/services/grid/gridService';
import Translations from '../../../../assets/locale/en/content.json';
import { useGridState } from '../../../../common/GridContext/GridContextProvider';
import { GridAPIResponse } from '../../../../common/interfaces/GridAPIResponse';
interface UseDataGridhookProps {
  baseUrl: string | null;
}
// this hook called the api and get data to be displayed on grid
const useDataGridhook = ({ baseUrl }: UseDataGridhookProps) => {
  const queryKey = 'GridData';
  const { isDefaultValueAdded } = useGridState();
  const { data, error, isLoading } = useQuery<GridAPIResponse, Error>({
    queryKey: [queryKey, baseUrl],
    queryFn: () => {
      if (baseUrl) {
        return getGridData(baseUrl);
      } else {
        return Promise.reject(new Error(Translations.TngGrid.baseUrlBullError));
      }
    },
    enabled: !!baseUrl && isDefaultValueAdded, // Only run query if baseUrl is not null or undefined
  });
  return { data, error, isLoading };
};

export default useDataGridhook;
