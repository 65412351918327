import pingAxiosInstance from './pingAxiosInstance';
import { ErrorResponse } from '../../../common/interfaces/ErroResponse';
import { Tokens } from '../../../common/interfaces/Tokens';
import {
  AuthenticateUserParams,
  OtpResponse,
  PasswordRecoveryParams,
  PasswordRecoveryResponse,
} from '../../../common/interfaces/UserAccount';
import store from '../../../store';
import { login, logout } from '../../../store/auth/authSlice';
import { setRefreshTokenLoading } from '../../../store/master/masterSlice';
import axiosInstance from '../../axiosInstance';
import { URLS, getUrl } from '../../urls';

export const initiateFlow = async (): Promise<string> => {
  try {
    const urlParams = {
      clientId: process.env.REACT_APP_CLIENT_ID!,
      // will update this code challenge code in future instead of manual we will create via code
      codeChallenge: 'oVBUs7txXI8x6dysz6fvYPlxlkUkinB-i9EE3rmti4w',
      redirectUri: process.env.REACT_APP_REDIRECT_URL!,
    };
    const url = getUrl(URLS.INITIATE_FLOW, urlParams);
    const res = await pingAxiosInstance.get<{ id: string }>(url);
    return res.data.id;
  } catch (error) {
    const err = JSON.stringify(error);
    throw new Error(err);
  }
};

export const authenticateUser = async (params: AuthenticateUserParams): Promise<string> => {
  const { flowId, userCredentials } = params;
  try {
    const url = getUrl(URLS.AUTHENTICATE_USER, { flowId });
    const response = await pingAxiosInstance.post<{ resumeUrl: string }>(url, userCredentials, {
      headers: {
        'Content-Type': 'application/vnd.pingidentity.usernamePassword.check+json',
      },
    });
    return response.data.resumeUrl;
  } catch (error: any) {
    const err = JSON.stringify(error);
    throw new Error(err);
  }
};

export const getAuthorizationCode = async (resumeUrl: string): Promise<string> => {
  try {
    const url = getUrl(URLS.GET_AUTHORIZATION_CODE, { resumeUrl });
    const response = await pingAxiosInstance.get<{
      status: string;
      authorizeResponse: { code: string };
    }>(url);
    if (response.data.status === 'COMPLETED') {
      return response.data.authorizeResponse.code;
    } else {
      throw new Error('Authorization code retrieval failed');
    }
  } catch (error) {
    console.error('Failed to retrieve authorization code:', error);
    const err = JSON.stringify(error);
    throw new Error(err);
  }
};

export const exchangeAuthorizationCode = async (
  authCode: string,
): Promise<{ accessToken: string; refreshToken: string }> => {
  try {
    const data = new URLSearchParams({
      grant_type: process.env.REACT_APP_GRANT_TYPE!,
      code: authCode,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL!,
      client_id: process.env.REACT_APP_CLIENT_ID!,
      code_verifier: 'lLbzJd_B-9s9WqC6zNR4s9QuTtM946rD2ENMilmfoM0',
      scope: 'access_token_five_mins',
    });

    const url = getUrl(URLS.EXCHANGE_AUTH_CODE);
    const response = await pingAxiosInstance.post<{
      access_token: string;
      refresh_token: string;
    }>(url, data.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (error) {
    const err = JSON.stringify(error);
    throw new Error(err);
  }
};

export const logoutUserService = async (): Promise<void> => {
  try {
    const url = getUrl(URLS.LOGOUT_USER);
    const response = await pingAxiosInstance.get(url);
    if (response.status === 302) {
      const redirectUrl = response.headers.location;
      // Handle the redirection manually in your frontend
      window.location.href = redirectUrl;
    }
  } catch (error: any) {
    if (error.response && error.response.status === 302) {
      const redirectUrl = error.response.headers.location;
      // Handle the redirection manually
      window.location.href = redirectUrl;
    } else {
      // Handle other errors
      console.error('Error:', error);
    }
  }
};

export const validateTokenService = async (token: string): Promise<boolean> => {
  try {
    const url = getUrl(URLS.VALIDATE_TOKEN);
    const response = await pingAxiosInstance.post(url, { token });
    return response.data.isValid;
  } catch (error) {
    throw new Error('Token validation failed');
  }
};

export const fetchOTPService = async (flowId: string, username: string): Promise<OtpResponse> => {
  try {
    const url = getUrl(URLS.AUTHENTICATE_USER, { flowId });

    const response = await pingAxiosInstance.post(
      url,
      { username },
      {
        headers: {
          'Content-Type': 'application/vnd.pingidentity.password.forgot+json',
        },
      },
    );

    if (!response.data) {
      throw new Error('No data received');
    }

    return response.data;
  } catch (error: any) {
    const errMessage = error.response?.data?.message || error.message || 'An error occurred';
    throw new Error(errMessage);
  }
};

// Function to recover Password
export const recoverPasswordService = async (
  params: PasswordRecoveryParams,
): Promise<PasswordRecoveryResponse> => {
  try {
    const url = getUrl(URLS.FORGOT_PASSWORD_RESET);

    const response = await axiosInstance.post(url, params);

    if (!response.data) {
      throw new Error('No data received');
    }

    return response.data;
  } catch (error: any) {
    // sent proper error to component
    const err = error.response.data;
    const { errorCode, message, detail } = err;
    const errorResponse: ErrorResponse = {
      responseCode: errorCode,
      responseMessage: message,
      detail: detail,
    };
    const errorMessage = `Code: ${errorResponse.responseCode}, Message: ${errorResponse.responseMessage}, Detail: ${errorResponse.detail}`;
    throw new Error(errorMessage);
  }
};

// Flag to track ongoing refresh requests
let refreshInProgress = false;
export const setNewRefreshToken = async (refreshToken: string): Promise<Tokens | null> => {
  // Return immediately if a refresh is already in progress

  if (refreshInProgress) {
    console.warn('Token refresh already in progress, ignoring subsequent calls');
    return null;
  }

  refreshInProgress = true;
  store.dispatch(
    setRefreshTokenLoading({
      isRefreshTokenLoading: true,
    }),
  );

  try {
    const urlParams = new URLSearchParams();
    urlParams.append('grant_type', 'refresh_token');
    urlParams.append('refresh_token', refreshToken);
    urlParams.append('client_id', process.env.REACT_APP_CLIENT_ID!);
    const url = getUrl(URLS.REFRESH_TOKEN);
    const response = await pingAxiosInstance.post(url, urlParams, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const { access_token, refresh_token } = response.data;

    if (access_token && refresh_token) {
      // Dispatch new tokens to the store
      store.dispatch(
        login({
          token: access_token,
          refreshToken: refresh_token,
        }),
      );
      store.dispatch(
        setRefreshTokenLoading({
          isRefreshTokenLoading: false,
        }),
      );
      return { accessToken: access_token }; // Return both tokens
    }

    return null; // Explicit return if no other condition is met
  } catch (error) {
    handleLogout();
    return null;
  } finally {
    refreshInProgress = false; // Reset the flag once the request completes
    store.dispatch(
      setRefreshTokenLoading({
        isRefreshTokenLoading: false,
      }),
    );
  }
};

// Consolidated logout and redirect function
const handleLogout = () => {
  store.dispatch(logout());
  window.location.href = '/';
};
