import { GridActions } from '../interfaces/GridActionInterfaces';
import { GridContextState } from '../interfaces/GridContextState';

// Action types
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORTING = 'SET_SORTING';
export const SET_SEARCH_BY_OF_SEARCH = 'SET_SEARCH_BY_OF_SEARCH';
export const SET_SEARCH_VALUE_OF_SEARCH = 'SET_SEARCH_VALUE_OF_SEARCH';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER = 'SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER';
export const SET_TOTAL_PAGE = 'SET_TOTAL_PAGE';
export const SET_TOTAL_RECORD = 'SET_TOTAL_RECORD';
export const SET_GRID_DATA = 'SET_GRID_DATA';
export const SET_GRID_MAPPED_DATA = 'SET_GRID_MAPPED_DATA';
export const SET_GRID_API = 'SET_GRID_API';
export const SET_LOADER = 'SET_LOADER';
export const SET_IS_FILTER_OPEN = 'SET_IS_FILTER_OPEN';
export const SET_IS_FILTER_APPLIED = 'SET_IS_FILTER_APPLIED';
export const SET_IS_SEARCH_APPLIED = 'SET_IS_SEARCH_APPLIED';
export const SET_IS_SORTING_APPLIED = 'SET_IS_SORTING_APPLIED';
export const SET_IS_DEFAULT_VALUE_APPLIED = 'SET_IS_DEFAULT_VALUE_APPLIED';

// all reducers for grid context
export const GridReducers = (state: GridContextState, action: GridActions) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, filters: action.payload };
    case SET_SEARCH:
      return { ...state, search: action.payload };
    case SET_SEARCH_BY_OF_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          searchBy: action.payload,
        },
      };
    case SET_SEARCH_VALUE_OF_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          searchValue: action.payload,
        },
      };
    case SET_SORTING:
      return { ...state, sorting: action.payload };
    case SET_PAGE_NUMBER:
      return { ...state, pageNumber: action.payload };
    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER:
      return { ...state, pageSize: action.payload.pageSize, pageNumber: action.payload.pageNumber };
    case SET_TOTAL_PAGE:
      return { ...state, totalPages: action.payload };
    case SET_TOTAL_RECORD:
      return { ...state, totalRecords: action.payload };
    case SET_GRID_DATA:
      return { ...state, gridData: action.payload };
    case SET_GRID_MAPPED_DATA:
      return { ...state, gridMappedData: action.payload };
    case SET_GRID_API:
      return { ...state, gridApi: action.payload };
    case SET_LOADER:
      return { ...state, loading: action.payload };
    case SET_IS_FILTER_OPEN:
      return { ...state, isFilterOpen: action.payload };
    case SET_IS_FILTER_APPLIED:
      return { ...state, isFilterApplied: action.payload };
    case SET_IS_SEARCH_APPLIED:
      return { ...state, isSearchApplied: action.payload };
    case SET_IS_SORTING_APPLIED:
      return { ...state, isSortingApplied: action.payload };
    case SET_IS_DEFAULT_VALUE_APPLIED:
      return { ...state, isDefaultValueAdded: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
