import { useQueries } from '@tanstack/react-query';

import { fetchProductList } from '../../../../api/services/masterData/masterData';
import { URLS } from '../../../../api/urls';

export const useFetchProductLists = () => {
  const queries = useQueries({
    queries: [
      {
        queryKey: ['WORKFLOW_AUTOMATION'],
        queryFn: () => fetchProductList(URLS.WORKFLOW_AUTOMATION),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['INVOICE_AUTOMATION'],
        queryFn: () => fetchProductList(URLS.INVOICE_AUTOMATION),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['DOCUMENT_AUTOMATION_AND_SECURITY'],
        queryFn: () => fetchProductList(URLS.DOCUMENT_AUTOMATION_AND_SECURITY),
        staleTime: 5 * 60 * 1000,
      },
    ],
  });

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  const data = {
    WORKFLOW_AUTOMATION: queries[0].data,
    INVOICE_AUTOMATION: queries[1].data,
    DOCUMENT_AUTOMATION: queries[2].data,
  };

  return { data, isLoading, isError };
};
