import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TngButton } from './TngButton';
import { TngGrid } from './TngGrid';
import { BottomPanelButtonProps } from '../../common/interfaces/BottomPanelButtonProps';

interface BottomPanelProps {
  prevButtonProps: BottomPanelButtonProps;
  nextButtonProps: BottomPanelButtonProps;
  setConfigStep: (step: number) => void; // Pass the setConfigStep function as a prop
  currentStep: number; // Receive the current step for additional logic if needed
  navigateToRoute: string;
  handleNextButton?: () => void;
}

export interface TngBottomPanelRef {
  setNextButtonState: (disabled: boolean) => void;
  setLoadingState: (loading: boolean) => void;
}

// Forward ref to access the component's methods
const TngBottomPanel = forwardRef<TngBottomPanelRef, BottomPanelProps>(
  (
    {
      prevButtonProps,
      nextButtonProps,
      setConfigStep,
      currentStep,
      navigateToRoute,
      handleNextButton,
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const handlePrevClick = () => {
      if (currentStep <= 0 && navigateToRoute) navigate(navigateToRoute);
      else if (currentStep > 0) setConfigStep(currentStep - 1);
    };

    // State for controlling the button's enabled/disabled state
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Expose methods to change the state of the buttons
    useImperativeHandle(ref, () => ({
      setNextButtonState: (disabled: boolean) => {
        setNextButtonDisabled(disabled);
      },
      setLoadingState: (loading: boolean) => {
        setIsLoading(loading);
      },
    }));

    const handleNextClick = () => {
      handleNextButton?.(); // Safely invoke optional prop
    };

    return (
      <TngGrid container className="bottom_panel_buttons">
        <TngGrid className="bottom_panel_prev_button">
          <TngButton onClick={handlePrevClick} {...prevButtonProps}>
            {prevButtonProps.value}
          </TngButton>
        </TngGrid>
        <TngGrid className="bottom_panel_next_button">
          <TngButton
            className={isLoading ? 'button_loading' : ''}
            onClick={handleNextClick}
            {...nextButtonProps}
            disabled={nextButtonDisabled}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {nextButtonProps.value}
          </TngButton>
        </TngGrid>
      </TngGrid>
    );
  },
);

TngBottomPanel.displayName = 'TngBottomPanel';

export default TngBottomPanel;
