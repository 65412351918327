import React, { forwardRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import CompanyInfoForm from './components/CompanyInfoForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

interface CompanyInformationProps {
  bottomPanelRef: any; // Use the custom type here
}

const CompanyInformation = forwardRef<HTMLDivElement, CompanyInformationProps>(
  ({ bottomPanelRef }, ref) => {
    useEffect(() => {
      const mainDiv = document.getElementById('stepper_component_container');
      focusFirstInteractiveElement(mainDiv);
    }, []);
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <Typography
            variant="h4"
            className="stepper_form_container_header"
            aria-label={Translations.ariaLabels.companyInformation}
          >
            1. {Translations.partnerOnboarding.companyInformation}
          </Typography>
          <Typography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </Typography>
        </TngGrid>
        <CompanyInfoForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

CompanyInformation.displayName = 'CompanyInformation';

export default CompanyInformation;
