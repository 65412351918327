import Translations from '../../../assets/locale/en/content.json';
import { LeadStatusResponse } from '../../../common/interfaces/lead/leadStatus';
import { Master, MasterResponse } from '../../../common/interfaces/Masters';
import { CompanyInfoSaveForm } from '../../../common/interfaces/partnerOnboarding/CompanyInfo';
import { PartnerOnboarding } from '../../../common/interfaces/partnerOnboarding/PartnerOnboarding';
import { UpdateDetailsFormModel } from '../../../pages/PartnerOnboarding/interfaces/updateDetailsFormModel';
import axiosInstance from '../../axiosInstance';
import { getUrl, URLS } from '../../urls';

/**
Create a lead in salesforce for onboarded partner from the API.
@param {string} companyInfo - details about company of partner
@throws {Error} - Throws an error if the request fails.
*/
export const createLeadForOnboardingPartner = async (companyInfo: CompanyInfoSaveForm) => {
  try {
    const baseURL = getUrl(URLS.CREATE_LEAD);

    const { data } = await axiosInstance.post(baseURL, companyInfo);
    return data;
  } catch (err: any) {
    throw new Error(Translations.common.formErrorMessage);
  }
};

/**
Update lead details in salesforce for an already registered partner from the API.
@param {string} details - details about company of partner
@throws {Error} - Throws an error if the request fails.
*/
export const updateDetailsForOnboardedPartner = async (
  details: UpdateDetailsFormModel,
  leadId: string,
) => {
  try {
    const baseURL = getUrl(URLS.LEAD_DETAILS, { leadId: leadId });

    const { data } = await axiosInstance.put(baseURL, details);
    return data;
  } catch (err: any) {
    throw new Error(Translations.common.formErrorMessage);
  }
};

/**
  Fetches the primary business from the API.
  @returns {Promise<Master[]>} - A promise that resolves to an array of primary-businesses.
  @throws {Error} - Throws an error if the request fails.
*/

export const fetchPrimaryBusiness = async (): Promise<Master[]> => {
  try {
    const url = getUrl(URLS.PRIMARY_BUSINESS);

    const { data } = await axiosInstance.get<MasterResponse>(url);
    return data.data;
  } catch (err: any) {
    throw new Error('Failed to fetch primary businesses');
  }
};

export const fetchPartnerOnboardingDetails = async (leadId: string): Promise<PartnerOnboarding> => {
  try {
    const url = getUrl(URLS.LEAD_DETAILS, { leadId });
    const response = await axiosInstance.get<PartnerOnboarding>(url);
    return response.data;
  } catch (error: any) {
    throw new Error('Failed to fetch partner onboarding details');
  }
};

export const fetchLeadStatus = async (): Promise<LeadStatusResponse> => {
  try {
    const url = getUrl(URLS.LEAD_STATUS);

    const { data } = await axiosInstance.get<LeadStatusResponse>(url);
    return data;
  } catch (error) {
    throw new Error('Failed to fetch lead status');
  }
};
