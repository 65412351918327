import React, { useState, useEffect, useRef } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Checkbox, ListItemText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Translations from '../../assets/locale/en/content.json';
import { Option } from '../../common/interfaces/Option';
import { ErrorProps } from '../../common/interfaces/SearchDropdown';
import { TngGrid, TngIconButton, TngTextField, TngTypography } from '../common';

interface CustomDropdownProps {
  dropdownLabel: string;
  options: Option[];
  selectedOptions: string[];
  placeholderText: string;
  handleSearchButtonClick: () => void;
  setSearchValue: (value: string) => void;
  searchValue: string;
  setOptionValue: (value: Option[]) => void;
  optionValue: Option[];
  error: ErrorProps;
  setError: React.Dispatch<React.SetStateAction<ErrorProps>>;
  setMaxOptionsLength?: number;
}

const TngMultiSelectSearchDropdown: React.FC<CustomDropdownProps> = ({
  dropdownLabel,
  options,
  selectedOptions,
  placeholderText,
  handleSearchButtonClick,
  setSearchValue,
  searchValue,
  setOptionValue,
  optionValue = [],
  error,
  setError,
  setMaxOptionsLength = 0,
}) => {
  const [selectedOptionsState, setSelectedOptionsState] = useState<string[]>(selectedOptions);
  const [localOptions, setLocalOptions] = useState<Option[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isTextFieldBlur, setTextFieldBlur] = useState(false);
  const textFieldRef = useRef<HTMLInputElement | null>(null); // Ref for the TextField

  useEffect(() => {
    setSelectedOptionsState(selectedOptions);
  }, [selectedOptions]);

  // function which return the union of 2 list of Option[] type by option Id
  const unionOfList = (arr1: Option[], arr2: Option[]): Option[] => {
    const map = new Map<string, Option>();

    arr1.concat(arr2).forEach((option) => {
      option.id && map.set(option.id, option); // Ensures only unique ids are kept
    });

    return Array.from(map.values());
  };

  useEffect(() => {
    if (options.length > 0 && !isTextFieldBlur) {
      const uniqueOptions = unionOfList(options, localOptions);
      setLocalOptions(uniqueOptions);
      setIsOpen(true);
    } else {
      setLocalOptions(optionValue);
    }
  }, [options, optionValue]);

  const handleSelectChange = (event: React.ChangeEvent<unknown>, value: Option[]) => {
    const selectedIds = value.map((option) => option.id);
    setSelectedOptionsState(selectedIds);
    setOptionValue(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchValue(input);
    (input.length === 0 || input.trim().length >= 3) && setError({ isError: false });
  };

  const handleBlur = () => {
    setIsOpen(false);
    setTextFieldBlur(true);
  };

  const handleFocusTextField = () => {
    textFieldRef.current?.focus();
  };

  return (
    <TngGrid container className="user_dropdown">
      <TngGrid item xs={8} className="order_status_text">
        <TngTypography variant="h5">{dropdownLabel}</TngTypography>
      </TngGrid>
      <TngGrid item xs={4}>
        <TngTypography
          style={{ textAlign: 'right' }}
          variant="body1"
        >{`${selectedOptionsState.length} Selected`}</TngTypography>
      </TngGrid>
      <TngGrid item xs={8} sm={10} md={10} lg={10}>
        <Autocomplete
          multiple
          open={isOpen}
          onClose={() => setIsOpen(false)}
          value={localOptions.filter((option) => selectedOptionsState.includes(option.id))}
          options={localOptions}
          inputValue={searchValue}
          onChange={handleSelectChange}
          renderInput={(params) => (
            <TngTextField
              {...params}
              placeholder={placeholderText}
              onChange={handleInputChange}
              value={searchValue}
              ref={textFieldRef}
              onBlur={handleBlur}
            />
          )}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props} key={option.id}>
                <Checkbox checked={selected} />
                <ListItemText primary={option.name} />
              </li>
            );
          }}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          componentsProps={{
            popupIndicator: {
              onClick: () => {
                setIsOpen(!isOpen);
              },
              tabIndex: 0,
              sx: {
                display: options.length > 0 || selectedOptionsState.length > 0 ? 'flex' : 'none',
              },
            },
          }}
          disableCloseOnSelect
          getOptionDisabled={(option) => {
            if (setMaxOptionsLength) {
              return (
                selectedOptionsState.length >= setMaxOptionsLength &&
                !selectedOptionsState.includes(option.id)
              );
            }
            return false;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchButtonClick();
              setTextFieldBlur(false);
            }
          }}
        />
      </TngGrid>
      <TngGrid item xs={4} sm={2} md={2} lg={2} className="dropdown_search_button">
        <TngIconButton
          aria-label={Translations.ariaLabels.searchName}
          onClick={() => {
            handleSearchButtonClick();
            handleFocusTextField();
            setTextFieldBlur(false);
          }}
          disableTouchRipple
          disableRipple
        >
          <SearchOutlinedIcon />
        </TngIconButton>
      </TngGrid>
      {error.isError && (
        <TngGrid item xs={12} role="alert" aria-live="assertive" className="error_text">
          {error.message}
        </TngGrid>
      )}
    </TngGrid>
  );
};

export default TngMultiSelectSearchDropdown;
