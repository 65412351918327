import Translations from '../../../../assets/locale/en/content.json';
import { FieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { ProductList } from '../../../../common/interfaces/partnerOnboarding/ProductList';

/**
Create a field config for form to render fields on UI
@parameter {WORKFLOW_AUTOMATION} - List of options for WORKFLOW_AUTOMATON field.
@parameter {INVOICE_AUTOMATION} - List of options for INVOICE_AUTOMATION field.
@parameter {DOCUMENT_AUTOMATION} - List of options for DOCUMENT_AUTOMATION field.
@returns FieldConfig[] - return field config
*/

export const GetInputFieldConfig = (
  WORKFLOW_AUTOMATION: ProductList[] | undefined,
  INVOICE_AUTOMATION: ProductList[] | undefined,
  DOCUMENT_AUTOMATION: ProductList[] | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.partnerOnboarding.workflowAutomation,
        htmlFor: 'workflowAutomationProducts',
        fieldType: 'chip',
        validationRules: { required: false },
        chipOptions: WORKFLOW_AUTOMATION,
        placeholder: '',
        allowMultiple: true,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.invoiceAutomation,
        htmlFor: 'invoiceAutomationProducts',
        fieldType: 'chip',
        validationRules: { required: false },
        chipOptions: INVOICE_AUTOMATION,
        placeholder: '',
        allowMultiple: true,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.documentAutomationSecurity,
        htmlFor: 'documentAutomationProducts',
        fieldType: 'chip',
        validationRules: { required: false },
        chipOptions: DOCUMENT_AUTOMATION,
        placeholder: '',
        allowMultiple: true,
      },
    ],
  ];
  return fieldConfig;
};
