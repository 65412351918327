import React, { forwardRef, useEffect } from 'react';

import MoreCompanyDetailsForm from './components/MoreCompanyDetailsForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

interface MoreCompanyDetailsProps {
  bottomPanelRef: any;
}

const MoreCompanyDetails = forwardRef<HTMLDivElement, MoreCompanyDetailsProps>(
  ({ bottomPanelRef }, ref) => {
    useEffect(() => {
      const mainDiv = document.getElementById('stepper_component_container');
      focusFirstInteractiveElement(mainDiv);
    }, []);
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <TngTypography
            variant="h4"
            className="stepper_form_container_header"
            aria-label={Translations.ariaLabels.moreCompanyDetails}
          >
            2. {Translations.partnerOnboarding.moreCompanyDetails}
          </TngTypography>
          <TngTypography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </TngTypography>
        </TngGrid>
        <MoreCompanyDetailsForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

MoreCompanyDetails.displayName = 'MoreCompanyDetails';

export default MoreCompanyDetails;
