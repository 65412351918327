import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PartnerOnboardingState } from './interface/partnerOnboardingStatus';

const initialState: PartnerOnboardingState = {
  status: null,
  leadId: null,
};
const partnerApplicationSlice = createSlice({
  name: 'partnerOnboardingStatus',
  initialState,
  reducers: {
    setPartnerApplication(state, action: PayloadAction<PartnerOnboardingState>) {
      return action.payload;
    },
    resetPartnerApplication(state) {
      state.status = null;
      state.leadId = null;
    },
  },
});

export const { setPartnerApplication, resetPartnerApplication } = partnerApplicationSlice.actions;

export default partnerApplicationSlice.reducer;
