import { RevenueTarget } from '../../interfaces/contextState';
import { RevenueTargetFormFields } from '../interface';

export const initializeRevenueTargetForm = (
  revenueTarget: RevenueTarget,
): RevenueTargetFormFields => {
  const { year1, year2, year3, businessProposition } = revenueTarget;
  return {
    year1: year1 || '',
    year2: year2 || '',
    year3: year3 || '',
    businessProposition: businessProposition || '',
  };
};
