import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { setNewRefreshToken } from '../api/services/auth/authService';
import useTokenListener from '../common/hooks/useTokenListener';
import { TngGrid, TngLoader } from '../components/common';
import { RootState } from '../store';
import isTokenValid from '../utils/jwtTokenValidation';

const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  const { refreshToken, token } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = token ? isTokenValid(token) : false;
  const isLoading = useSelector((state: RootState) => state.master.isLoading);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  useTokenListener();

  useEffect(() => {
    const refreshAccessToken = async () => {
      if (!isAuthenticated && refreshToken) {
        setIsRefreshing(true); // Start refreshing state
        try {
          await setNewRefreshToken(refreshToken);
        } catch (error) {
          console.error('Failed to refresh token:', error);
        } finally {
          setIsRefreshing(false); // End refreshing state
        }
      } else if (!refreshToken) {
        navigate('/');
      }
    };

    refreshAccessToken();
  }, [isAuthenticated, refreshToken, navigate]);

  if (isRefreshing || isLoading) {
    return (
      <TngGrid className="custom-loader-overlay">
        <TngLoader />
      </TngGrid>
    );
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  // Return null if not authenticated to prevent rendering anything
  return null;
};

export default ProtectedRoute;
