import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

import Translations from '../../../../../assets/locale/en/content.json';
import { NavigationUrl } from '../../../../../common/interfaces/userAdmin/Menu';
import { TngGrid, TngTypography } from '../../../../common';

export const NavigationLink = ({
  navigationUrl,
  itemName,
}: {
  navigationUrl: NavigationUrl;
  itemName: string;
}) => {
  const { url, isSSOEnabled } = navigationUrl;

  return (
    <TngGrid className="navigation_link_grid">
      <TngTypography>{itemName}</TngTypography>
      <Link
        className="navigation_link"
        to={
          isSSOEnabled
            ? url === '/kofaxcommunity' // For SSO-Community
              ? process.env.REACT_APP_SSO_COMMUNITY_URL!
              : url === '/idp/login?app=0sp6R000000wk74' // For SSO-HighSpot
                ? `${process.env.REACT_APP_SSO_URL}${url}${process.env.REACT_APP_HIGH_SPOT_EXTEND_URL ?? ''}`
                : `${process.env.REACT_APP_SSO_URL}${url}`
            : url
        }
        target="_blank"
        aria-label={`${Translations.ariaLabels.goToLabel} ${itemName}`}
      >
        <OpenInNewIcon />
      </Link>
    </TngGrid>
  );
};
