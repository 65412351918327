import React from 'react';

import { TngDivider, TngGrid, TngTypography } from '../../../components/common';
import TngMultiAgGrid from '../../../components/TngGridSection/TngMultiAgGrid/TngMultiAgGrid';
import { getGridConfig } from '../gridHelper/gridConfig';
import { GlobalPriceListGrid } from '../interfaces/GlobalPriceListGrid';

interface PartnerProgramPriceGridProps {
  category: string;
  items: GlobalPriceListGrid[];
}

const PartnerProgramPriceGrid: React.FC<PartnerProgramPriceGridProps> = ({ category, items }) => {
  return (
    <TngGrid className="partner_profile_grid_wrapper">
      <TngGrid className="partner_profile_grid_header_wrapper">
        <TngTypography variant="h6" className="partner_profile_grid_header">
          {category}
        </TngTypography>
        <TngDivider />
      </TngGrid>

      <TngGrid className="partner_profile_grid">
        <TngMultiAgGrid gridConfig={getGridConfig(items)} />
      </TngGrid>
    </TngGrid>
  );
};

export default PartnerProgramPriceGrid;
