import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputLabel, SelectChangeEvent } from '@mui/material';

import { dropdownValues } from './helper/dataHelper';
import Translations from '../../../../../assets/locale/en/content.json';
import { GridActionEnum } from '../../../../../common/enums/GridActionEnum';
import {
  useGridDispatch,
  useGridState,
} from '../../../../../common/GridContext/GridContextProvider';
import { TngGrid, TngMenuItem, TngSelect } from '../../../../common';

const TngPaginationDropdown = () => {
  const { pageSize, totalRecords } = useGridState();
  const dispatch = useGridDispatch();

  const handleChange = (event: SelectChangeEvent<number | string>) => {
    const newPageSize = parseInt(String(event.target.value), 10);
    //update new page size means container either 10/20/50/100 records
    dispatch({
      type: GridActionEnum.SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER,
      payload: { pageSize: newPageSize, pageNumber: 1 },
    });
  };

  return (
    <TngGrid container className="pagination_dropdown">
      <TngGrid item>
        <InputLabel className="pagination_dropdown_text">
          {Translations.orderHistory.rowsPerPage}
        </InputLabel>
      </TngGrid>
      <TngGrid item>
        <TngSelect
          className="pagination_dropdown_select"
          value={pageSize}
          onChange={handleChange}
          disabled={totalRecords <= 10}
          displayEmpty
          IconComponent={KeyboardArrowDownIcon}
          inputProps={{
            className: 'pagination_dropdown_box',
            'data-testid': 'pagination_dropdown',
            'aria-label': Translations.orderHistory.rowsPerPageDropdown,
          }}
          MenuProps={{
            classes: {
              paper: 'pagination_option_menu_paper',
              list: 'pagination_dropdown_menu',
            },
            MenuListProps: {
              role: 'listbox',
              'aria-label': `${Translations.orderHistory.rowsPerPageDropdown} expanded`,
            },
          }}
        >
          {dropdownValues.map((options) => (
            <TngMenuItem
              className="pagination_dropdown_items"
              key={options.value}
              value={options.value}
            >
              {options.label}
            </TngMenuItem>
          ))}
        </TngSelect>
      </TngGrid>
    </TngGrid>
  );
};

export default TngPaginationDropdown;
