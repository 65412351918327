import { ErrorResponse } from '../common/interfaces/ErroResponse';

export const parseErrorMessage = (errorMessage: string): ErrorResponse => {
  // Extract code and message from the error message
  const regex = /Code:\s*([^,]+),\s*Message:\s*([^,]+),\s*Detail:\s*(.+)/;
  const match = errorMessage.match(regex);
  if (match) {
    const responseCode = match[1];
    const responseMessage = match[2];
    const detail = match[3];

    return {
      responseCode,
      responseMessage,
      detail,
    };
  } else {
    // Default response if parsing fails
    return {
      responseCode: 'manual',
      responseMessage: 'Unknown error occurred',
      detail: '',
    };
  }
};
