import React, { useState } from 'react';

import { Avatar, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';

import NavbarList from './components/NavbarList';
import TungstenAutomationLogoBlue from '../../../../assets/images/tungsten_logo.svg';
import Translations from '../../../../assets/locale/en/content.json';
import { UserTypesEmuns } from '../../../../common/enums/UserTypesEmuns';
import { useUserLogout } from '../../../../common/hooks/useUserLogout';
import {
  TngAlertBox,
  TngDivider,
  TngGrid,
  TngImage,
  TngList,
  TngPopover,
  TngTypography,
} from '../../../../components/common';
import { RootState } from '../../../../store';
import { stringAvatar } from '../../../../utils/getUserInitials';

interface NavbarProps {
  isContentVisible?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isContentVisible = true }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const userState = useSelector((state: RootState) => state.user.user);

  const isTemporaryUser = authState.userRole === UserTypesEmuns.PTU;
  const { firstName, lastName } = isTemporaryUser ? authState : userState;
  const userName = `${firstName ?? ''}${firstName && lastName ? ' ' : ''}${lastName ?? ''}`;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const { logoutUser } = useUserLogout();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setAlertOpen(true);
    handleClosePopover();
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmLogout = async () => {
    await logoutUser();
    setAlertOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <TngGrid container className="navbar_parent_container">
      <TngGrid item xs={12} sm={2} md={2} lg={2}>
        <TngImage
          src={TungstenAutomationLogoBlue}
          alt="Tungsten Automation Logo"
          className="nav_logo"
        />
      </TngGrid>

      {isContentVisible && (
        <TngGrid item xs={12} sm={10} md={10} lg={10}>
          <TngGrid container className="navbar_content_container">
            <TngDivider orientation="vertical" />
            <TngGrid item className="navbar_username_text">
              <TngTypography variant="body2">{userName}</TngTypography>
            </TngGrid>
            <TngGrid item className="navbar_avatar">
              <IconButton
                aria-label={Translations.ariaLabels.userProfile}
                disableRipple
                disableTouchRipple
                onClick={handleClick}
              >
                <Avatar alt="User Image">{stringAvatar(userName)}</Avatar>
              </IconButton>
            </TngGrid>

            <TngPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              className="navbar_popover"
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <TngList className="popover_list">
                <NavbarList
                  onAccountSettingsClick={handleClosePopover}
                  onLogoutClick={handleLogoutClick}
                />
              </TngList>
            </TngPopover>
          </TngGrid>
        </TngGrid>
      )}

      <TngAlertBox
        open={alertOpen}
        title={Translations.logOut.confirmLogout}
        message={Translations.logOut.logOutParagraph}
        onClose={handleAlertClose}
        onConfirm={handleConfirmLogout}
        cancelText={Translations.logOut.cancel}
        confirmText={Translations.logOut.yesLogout}
      />
    </TngGrid>
  );
};

export default Navbar;
