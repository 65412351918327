/**
Initialize the react hook form with default/empty value
@returns UserFormField- object of manage user form fields
*/
import { CompanyInfoField } from '../interfaces';

export const initializeCompanyInfoForm = () => {
  const defaultValues: CompanyInfoField = {
    companyName: '',
    websiteURL: '',
    country: '',
    state: '',
    city: '',
    street: '',
    postalCode: '',
    phoneNumber: '',
    fax: '',
    jobTitle: '',
  };
  return defaultValues;
};
