import { InternalAxiosRequestConfig, AxiosHeaders } from 'axios';

import axiosInstance from './axiosInstance';
import { requestErrorHandler } from './interceptorErrorHandler';
import { logoutUserService, setNewRefreshToken } from './services/auth/authService';
import { Tokens } from '../common/interfaces/Tokens';
import store from '../store';
import { logout } from '../store/auth/authSlice';
import { setError } from '../store/master/masterSlice';
import isTokenValid from '../utils/jwtTokenValidation';

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  interceptorEnabled?: boolean;
}

// Variables to manage token refresh logic
let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}> = [];

// Max retry limit
const MAX_RETRIES = 2;

const processQueue = (error: any, tokens: { accessToken: string } | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (tokens) {
      prom.resolve(tokens);
    }
  });
  failedQueue = [];
};

// Function to set up interceptors
export const setupInterceptors = () => {
  axiosInstance.interceptors.request.use(async (config: CustomAxiosRequestConfig) => {
    if (config.interceptorEnabled === false) {
      return config; // Skip custom logic
    }
    const { token, refreshToken } = store.getState().auth;

    // Check token validity
    if (token && !isTokenValid(token)) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          if (!refreshToken) {
            throw new Error('Refresh token is missing');
          }
          const newTokens = await setNewRefreshToken(refreshToken);
          if (newTokens?.accessToken) {
            // Update headers with the new access token
            config.headers.set('Authorization', `Bearer ${newTokens.accessToken}`);
            config.headers.set('Access-Token', newTokens.accessToken);
          } else {
            throw new Error('Failed to refresh token');
          }
        } catch (err) {
          return Promise.reject(err); // Handle failure in token refresh
        } finally {
          isRefreshing = false; // Reset the flag
        }
      } else {
        // Wait for the current refresh process
        return new Promise((resolve, reject) => {
          failedQueue.push({
            resolve: (tokens: Tokens) => {
              config.headers.set('Authorization', `Bearer ${tokens.accessToken}`);
              config.headers.set('Access-Token', tokens.accessToken);
              resolve(config);
            },
            reject: (err) => reject(err),
          });
        });
      }
    } else {
      // Add the token to the request headers
      config.headers = config.headers || new AxiosHeaders();
      config.headers.set('Authorization', `Bearer ${token}`);
      config.headers.set('Access-Token', token);
    }

    return config;
  }, requestErrorHandler);

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const { token, refreshToken } = store.getState().auth;

      // Check if the user is authenticated or not
      if (error.response && !token) {
        // Return the error as is to handle it at the page level
        return Promise.reject(error);
      }

      // Initialize retry count if not set
      if (!originalRequest._retryCount) {
        originalRequest._retryCount = 0;
      }
      // Check if error is due to timeout
      if (error.code === 'ECONNABORTED') {
        store.dispatch(setError({ isError: true }));
      }
      if (
        error.response &&
        error.response.status !== 403 &&
        error.response.status !== 401 &&
        originalRequest._retryCount < MAX_RETRIES
      ) {
        store.dispatch(setError({ isError: true }));
      }
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403) &&
        originalRequest._retryCount < MAX_RETRIES
      ) {
        if (isRefreshing) {
          // Queue the request if a refresh is already in progress
          return new Promise<Tokens>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(async (tokens) => {
              const typedTokens = tokens as Tokens; // Type assertion
              originalRequest.headers['Authorization'] = `Bearer ${typedTokens.accessToken}`;
              originalRequest.headers['Access-Token'] = typedTokens.accessToken;
              return axiosInstance(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        // Check if the refresh token is invalid
        if (token && !isTokenValid(token)) {
          originalRequest._retry = true;
          originalRequest._retryCount += 1; // Increment retry count
          isRefreshing = true;
          if (refreshToken) {
            return new Promise((resolve, reject) => {
              setNewRefreshToken(refreshToken)
                .then((tokens) => {
                  store.dispatch({ type: 'SET_TOKEN', payload: tokens }); // Update token in the store
                  processQueue(null, tokens); // Process all queued requests
                  originalRequest.headers['Authorization'] = `Bearer ${tokens?.accessToken}`;
                  originalRequest.headers['Access-Token'] = tokens?.accessToken;
                  resolve(axiosInstance(originalRequest)); // Retry the request with the new tokens
                })
                .catch((refreshError) => {
                  processQueue(refreshError, null);
                  reject(refreshError); // Reject if refresh fails
                })
                .finally(() => {
                  isRefreshing = false; // Reset refresh flag
                });
            });
          } else {
            // If no refresh token is present, immediately reject and trigger logout
            processQueue(new Error('No refresh token available'), null);
            token && (await logoutUserService());
            store.dispatch(logout());
            window.location.href = '/';
            return Promise.reject(new Error('No refresh token available'));
          }
        } else {
          // If the refresh token is still valid, return an error response immediately
          store.dispatch(setError({ isError: true }));
        }
      }

      // If max retry count reached, dispatch logout
      if (originalRequest._retryCount >= MAX_RETRIES) {
        token && (await logoutUserService());
        store.dispatch(logout());
      }

      return Promise.reject(error);
    },
  );
};

// Initialize interceptors
setupInterceptors();

export default axiosInstance;
