import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { fetchMenus } from '../../../../../api/services/userAdmin/userAdminService';
import { Menu } from '../../../../../common/interfaces/userAdmin/Menu';
import { RootState } from '../../../../../store';

/**
Custom hook to fetch the menu using React Query.
@returns {object} - An object containing states data, error, and loading state.
*/
export const useMenu = (isAuthenticated: boolean) => {
  const userType = useSelector((state: RootState) => state.auth.userRole);
  const { data, error, isLoading } = useQuery<Menu[], Error>({
    queryKey: ['menu', userType],
    queryFn: fetchMenus,
    enabled: isAuthenticated,
  });
  return { data, error, isLoading };
};
