import { useCallback, useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createLeadForOnboardingPartner } from '../../../api/services/partnerOnboarding/partnerOnboardingService';
import Translations from '../../../assets/locale/en/content.json';
import { CompanyInfoSaveForm } from '../../../common/interfaces/partnerOnboarding/CompanyInfo';
import { useToast } from '../../../components/Toasts/ToastContext';
import {
  usePartnerOnboardingDispatch,
  usePartnerOnboardingState,
} from '../context/ContextProvider';
import { ContextActionEnum } from '../enums/ContextActionEnums';

export const useCreateLeadForPartner = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = usePartnerOnboardingDispatch();
  const { stepsCompleted, activeStep } = usePartnerOnboardingState();

  const queryClient = useQueryClient();

  // toast hook to display toast messages
  const { showToast } = useToast();

  const mutation = useMutation({
    mutationFn: async ({ companyInfo }: { companyInfo: CompanyInfoSaveForm }) => {
      setLoading(true);
      return await createLeadForOnboardingPartner(companyInfo);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      setLoading(false);
      dispatch({ type: ContextActionEnum.UPDATE_STEP_COMPLETED, payload: stepsCompleted + 1 });
      dispatch({ type: ContextActionEnum.UPDATE_ACTIVE_STEP, payload: activeStep + 1 });
    },
    onError: () => {
      // display toast for error
      showToast(Translations.common.formErrorMessage, {
        type: 'error',
      });
    },
  });

  const createLeadForPartner = useCallback(
    async (companyInfo: CompanyInfoSaveForm) => {
      try {
        const data = await mutation.mutateAsync({ companyInfo });
        return { leadId: data.value, success: true };
      } catch (err: any) {
        return { success: false, error: err.message };
      } finally {
        setLoading(false); // Ensure loading state is updated
      }
    },
    [mutation],
  );

  return { createLeadForPartner, loading };
};
