import React, { useEffect } from 'react';

import { Stepper, Step, StepLabel } from '@mui/material';

import { TngGrid } from './TngGrid';
import { TngTypography } from './TngTypography';

interface StepperProps {
  orientation: 'horizontal' | 'vertical';
  steps: string[];
  stepComponents: JSX.Element[];
  activeStep: number;
  stepperHeading?: string;
  setActiveStep: (step: number) => void;
  completedStep: number;
  setCompletedStep: (step: number) => void;
}

const TngStepper: React.FC<StepperProps> = ({
  orientation,
  stepComponents,
  steps,
  activeStep,
  stepperHeading,
  setActiveStep,
  completedStep,
  setCompletedStep,
}) => {
  const StepComponent = ({ step }: { step: number }) => {
    return stepComponents[step];
  };

  const handleStepClick = (step: number) => {
    if (step <= completedStep) {
      setActiveStep(step);
    }
  };

  useEffect(() => {
    if (activeStep > completedStep) {
      setCompletedStep(activeStep);
    }
  }, [activeStep, completedStep]);

  return (
    <TngGrid className="stepper_container" data-testid="stepper">
      <TngGrid className="stepper_grid">
        <TngTypography className="stepper_heading">{stepperHeading}</TngTypography>
        <Stepper activeStep={completedStep} orientation={orientation}>
          {steps.map((label, index) => (
            <Step
              data-testid={`step-${index}`}
              key={label}
              onClick={() => handleStepClick(index)}
              onKeyDown={(event) => event.key === 'Enter' && handleStepClick(index)}
              tabIndex={0}
            >
              <StepLabel
                slotProps={{
                  label: {
                    className: activeStep === index ? 'step-label-active' : '',
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </TngGrid>
      <TngGrid className="stepper_components_grid">
        <StepComponent step={activeStep} />
      </TngGrid>
    </TngGrid>
  );
};

export default TngStepper;
