import React from 'react';

const ManageProspectsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9.5C14.2092 9.5 16 7.70915 16 5.5C16 3.29086 14.2092 1.5 12 1.5C9.79085 1.5 8 3.29086 8 5.5C8 7.70915 9.79085 9.5 12 9.5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13 12.0515C12.6716 12.0175 12.338 12 12 12C7.02975 12 3 15.7811 3 20.4447C6.3331 21.2264 9.6666 21.5738 13 21.4869"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17.25 20.5C19.321 20.5 21 18.821 21 16.75C21 14.679 19.321 13 17.25 13C15.179 13 13.5 14.679 13.5 16.75C13.5 18.821 15.179 20.5 17.25 20.5Z"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M22.25 21.75L20 19.5L20.25 19.75"
        stroke="#00A0FB"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ManageProspectsIcon;
