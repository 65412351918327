import { CompanyInformation } from '../../interfaces/contextState';
import { CompanyInfoField } from '../interfaces';

export const companyInfoFormMapper = (data: CompanyInformation): CompanyInfoField => {
  return {
    companyName: data.companyName,
    websiteURL: data.websiteURL,
    country: data.countryCode,
    state: data.stateCode,
    city: data.city,
    street: data.street,
    postalCode: data.postalCode,
    phoneNumber: data.phoneNumber,
    fax: data.fax,
    jobTitle: data.jobTitle,
  };
};
