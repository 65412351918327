import Translations from '../../../../assets/locale/en/content.json';
import { FieldConfig } from '../../../../common/interfaces/InputFieldConfig';

/**
Create a field config for form to render fields on UI
*/

export const GetInputFieldConfig = (): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.partnerOnboarding.isAgreeTermsAndCondition,
        htmlFor: 'isAgreeTermsAndCondition',
        fieldType: 'checkbox',
        validationRules: { required: true },
        placeholder: '',
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.isRequestMarketing,
        htmlFor: 'isRequestMarketing',
        fieldType: 'checkbox',
        validationRules: { required: false },
        placeholder: '',
      },
    ],
  ];
  return fieldConfig;
};
