import React, { forwardRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import TermsAndConditionForm from './components/TermsAndConditionForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

interface TermsAndConditionsProps {
  bottomPanelRef: any;
}

const TermsAndConditions = forwardRef<HTMLDivElement, TermsAndConditionsProps>(
  ({ bottomPanelRef }, ref) => {
    useEffect(() => {
      const mainDiv = document.getElementById('stepper_component_container');
      focusFirstInteractiveElement(mainDiv);
    }, []);
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <Typography
            variant="h4"
            className="stepper_form_container_header"
            aria-label={Translations.ariaLabels.termsConditions}
          >
            5. {Translations.partnerOnboarding.termsAndConditions}
          </Typography>
          <Typography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </Typography>
        </TngGrid>
        <TermsAndConditionForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

TermsAndConditions.displayName = 'TermsAndConditions';

export default TermsAndConditions;
