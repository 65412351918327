import React, { forwardRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import ProductOffersForm from './components/ProductOffersForm';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid } from '../../../components/common';
import { focusFirstInteractiveElement } from '../../../utils/findInteractiveElement';

interface ProductsYouWantToOfferProps {
  bottomPanelRef: any;
}

const ProductsYouWantToOffer = forwardRef<HTMLDivElement, ProductsYouWantToOfferProps>(
  ({ bottomPanelRef }, ref) => {
    useEffect(() => {
      const mainDiv = document.getElementById('stepper_component_container');
      focusFirstInteractiveElement(mainDiv);
    }, []);
    return (
      <TngGrid className="stepper_form_container">
        <TngGrid className="stepper_form_container_header_container">
          <Typography
            variant="h4"
            className="stepper_form_container_header"
            aria-label={Translations.ariaLabels.productsOffer}
          >
            4. {Translations.partnerOnboarding.productsYouWantToOffer}
          </Typography>
          <Typography className="stepper_form_container_helper_text">
            {Translations.partnerOnboarding.formRequiredFieldText}
          </Typography>
        </TngGrid>
        <TngGrid className="stepper_form_helper_text">
          {Translations.partnerOnboarding.productInterestHelperText}
        </TngGrid>
        <ProductOffersForm ref={ref} bottomPanelRef={bottomPanelRef} />
      </TngGrid>
    );
  },
);

ProductsYouWantToOffer.displayName = 'ProductsYouWantToOffer';

export default ProductsYouWantToOffer;
