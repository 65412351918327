import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import PartnerProgramPriceGrid from './PartnerProgramPriceGrid';
import Translations from '../../../assets/locale/en/content.json';
import { TngGrid, TngLoader } from '../../../components/common';
import TngNoResultFound from '../../../components/common/TngNoResultFound';
import { RootState } from '../../../store';
import { mappedDataAccToCategory } from '../gridHelper/dataMapper';
import { useGetGlobalPriceList } from '../hooks/useGetGlobalPriceList';
import { GlobalPriceListGrid } from '../interfaces/GlobalPriceListGrid';

const PartnerProfileGridContainer = () => {
  const { accountId } = useSelector((state: RootState) => state.user.user);
  const { data: globalPriceList, isLoading } = useGetGlobalPriceList(accountId);

  const mappedCategorizedPriceLists = useMemo<{ [key: string]: GlobalPriceListGrid[] }>(() => {
    if (globalPriceList && globalPriceList.length > 0) {
      return mappedDataAccToCategory(globalPriceList);
    }
    return {};
  }, [globalPriceList]);

  return (
    <>
      {isLoading ? (
        <TngLoader />
      ) : (
        <TngGrid className="partner_profile_grid_container">
          {(() => {
            // Creating an immediate invoke function expression to avoid using Object.keys multiple times.
            const categories = Object.keys(mappedCategorizedPriceLists);
            return categories.length > 0 ? (
              categories.map((category) => (
                <PartnerProgramPriceGrid
                  key={category}
                  category={category}
                  items={mappedCategorizedPriceLists[category]}
                />
              ))
            ) : (
              <TngNoResultFound message={Translations.common.noListAvailable} />
            );
          })()}
        </TngGrid>
      )}
    </>
  );
};

export default PartnerProfileGridContainer;
