import React from 'react';

import { Controller, FieldErrors, FieldValues, Path, Control } from 'react-hook-form';

import Translations from '../../assets/locale/en/content.json';
import { InputFieldConfig } from '../../common/interfaces/InputFieldConfig';
import { TngGrid, TngCheckbox } from '../common';

interface FieldRenderingProps<TFormField extends FieldValues> {
  field: InputFieldConfig;
  errors: FieldErrors<TFormField>;
  control: Control<TFormField>;
}

const CheckboxField = <TFormField extends FieldValues>({
  field,
  errors,
  control,
}: FieldRenderingProps<TFormField>) => {
  return (
    <TngGrid item key={field.label} className="form_field">
      <Controller
        name={field.htmlFor as Path<TFormField>}
        control={control}
        render={({ field: { onChange, value = false } }) => (
          <TngCheckbox
            key={field.htmlFor}
            keyId={field.htmlFor}
            checked={value}
            ariaLabel={
              field.validationRules.required
                ? `${field.label} ${Translations.ariaLabels.mandatoryField}`
                : field.label
            }
            handleToggleCheckbox={(e) => onChange(e.target.checked)}
            label={field.validationRules.required ? '* ' + field.label : field.label}
            className={`form_checkbox_field ${
              errors[field.htmlFor as Path<TFormField>] ? 'form_checkbox_field_error' : ''
            }`}
            aria-invalid={!!errors[field.htmlFor as Path<TFormField>]}
            disableRipple
          />
        )}
      />
      {errors[field.htmlFor as Path<TFormField>] && (
        <span className="form_field_error">{Translations.Login.fieldError}</span>
      )}
    </TngGrid>
  );
};

export default CheckboxField;
