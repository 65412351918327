export const RECOVERY_CODE_REQUIRED = 'RECOVERY_CODE_REQUIRED';
export const DEFAULT_ERROR_MESSAGE = 'An error occurred';
export const SSO_SALES_SUPPORT_URL = '/s/sales-support';
export const TUNGSTEN_TERMS_OF_USE_URL = 'https://www.tungstenautomation.com/legal/terms-of-use';
export const TUNGSTEN_PRIVACY_POLICY_URL = 'https://www.tungstenautomation.com/Legal/Privacy';
export const OTP_SENT = 'otp_sent';
export const SUCCESS = 'success';
export const INVALID_OTP = 'invalid_otp';
export const PASSWORD_POLICY_CHECK_FAILURE = 'password_policy_check_failure';
export const REGENERATE_OTP = 'regenerate_otp';
