import React, { useState } from 'react';

import CustomerDropdown from './CustomerDropdown';
import FilterOrderStatus from './OrderStatus';
import PODates from './PODates';
import SoldThroughDropdown from './SoldThroughDropdown';
import { GridActionEnum } from '../../../../common/enums/GridActionEnum';
import { useGridDispatch, useGridState } from '../../../../common/GridContext/GridContextProvider';
import { StatusWithColor } from '../../../../common/interfaces/StatusColor';
import { TngApplyFilterBtn, TngDivider, TngGrid } from '../../../../components/common';
import { OrderHistoryFilter } from '../../modals/OrderHistoryFilter';
import { OrderHistoryFilterContext } from '../../modals/OrderHistoryFilterContext';
import {
  checkIfFiltersChanged,
  initializeFilterState,
  isAnyFilterSelected,
} from '../../utils/filterHelper';

interface FilterProps {
  orderStatuses: StatusWithColor[];
}

export const Filter: React.FC<FilterProps> = ({ orderStatuses }) => {
  const { filters }: { filters: OrderHistoryFilterContext } = useGridState();
  const dispatch = useGridDispatch();

  const [filterObj, setFiltersObj] = useState<OrderHistoryFilter>(initializeFilterState(filters));
  const defaultFilterState: OrderHistoryFilter = initializeFilterState(filters);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [poDateError, setPoDateError] = useState<string | null>(null);

  // Check if there are any active filters
  const hasFilters = checkIfFiltersChanged(filterObj, defaultFilterState);

  const applyFilter = () => {
    const poDateFrom = filterObj.poDateFrom?.format('YYYY-MM-DD') || null;
    const poDateTo = filterObj.poDateTo?.format('YYYY-MM-DD') || null;
    dispatch({
      type: GridActionEnum.SET_FILTERS,
      payload: { ...filterObj, poDateFrom, poDateTo },
    });
    dispatch({ type: GridActionEnum.SET_IS_FILTER_OPEN, payload: false });
    // check it no filter is selected
    if (isAnyFilterSelected(filterObj)) {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: true });
    } else {
      dispatch({ type: GridActionEnum.SET_IS_FILTER_APPLIED, payload: false });
    }
  };
  return (
    <TngGrid container className="order_history_filter filter_container">
      <FilterOrderStatus
        orderStatuses={orderStatuses}
        filterObj={filterObj}
        setFiltersObj={setFiltersObj}
      />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <CustomerDropdown filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <SoldThroughDropdown filterObj={filterObj} setFiltersObj={setFiltersObj} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <PODates filterObj={filterObj} setFiltersObj={setFiltersObj} poDateError={poDateError} />

      <TngDivider orientation="horizontal" variant="middle" flexItem />

      <TngApplyFilterBtn hasFilters={hasFilters} applyFilter={applyFilter} />
    </TngGrid>
  );
};
