import React from 'react';

const RegisterNewLeadWAIVAIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 2.5H6C4.61929 2.5 3.5 3.61929 3.5 5V20C3.5 21.3807 4.61929 22.5 6 22.5H9.75M16 2.5H18C19.3807 2.5 20.5 3.61929 20.5 5V10.75"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8 4V2.25C8 1.55964 8.55965 1 9.25 1H14.75C15.4403 1 16 1.55964 16 2.25V4H8Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.0569 22.2593L20.9657 16.3505C21.675 15.6412 21.675 14.4912 20.9657 13.7819C20.2564 13.0727 19.1065 13.0727 18.3972 13.7819L12.4884 19.6907L12.25 22.4977L15.0569 22.2593Z"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M8.25 10.75L11 13.5L16 8.5"
        stroke="#8094AA"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default RegisterNewLeadWAIVAIcon;
