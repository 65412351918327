import React from 'react';

import { PartnerOnboardingContextProvider } from './context/ContextProvider';
import PartnerOnboardingWorkflow from './PartnerOnboardingWorkflow';

const PartnerOnboarding = () => {
  return (
    <PartnerOnboardingContextProvider>
      <PartnerOnboardingWorkflow />
    </PartnerOnboardingContextProvider>
  );
};

export default PartnerOnboarding;
