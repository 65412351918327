import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import PartnerProfileGridContainer from './components/PartnerProfileGridContainer';
import PartnerProgramPriceList from './components/PartnerProgramPriceList';
import Translations from '../../assets/locale/en/content.json';
import { TngGrid, TngTypography } from '../../components/common';
import { focusFirstInteractiveElement } from '../../utils/findInteractiveElement';

const PartnerProgramPrice: React.FC = () => {
  // custom hook to focus on first interactive element
  const location = useLocation();
  useEffect(() => {
    const mainDiv = document.getElementById('main-content');
    focusFirstInteractiveElement(mainDiv);
  }, [location]);
  return (
    <TngGrid
      container
      className="partner_program_price_list_parent_container"
      id="partner_program_price_list"
    >
      <TngGrid container className="partner_program_price_list_child_container">
        <TngGrid item xs={12} className="partner_program_price_list_main_heading">
          <TngTypography variant="h2" tabIndex={0}>
            {Translations.PartnerProgram.partnerProgramPriceList}
          </TngTypography>
        </TngGrid>
        <TngGrid item xs={12} className="partner_program_price_list_tab_container">
          <PartnerProgramPriceList />
          <PartnerProfileGridContainer />
        </TngGrid>
      </TngGrid>
    </TngGrid>
  );
};

export default PartnerProgramPrice;
