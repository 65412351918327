import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Country } from '../../common/interfaces/Country';
import { State } from '../../common/interfaces/State';
import { UserTypes } from '../../common/interfaces/user/UserTypes';
import { Icons } from '../../common/interfaces/userAdmin/Icons';
import { Menu } from '../../common/interfaces/userAdmin/Menu';

interface MasterState {
  isLoading: boolean;
  countries: Country[];
  states: State[];
  icons: Icons[];
  userTypes: UserTypes[];
  isPermissionsLoading: boolean;
  isRefreshTokenLoading: boolean;
  isError: boolean;
  menu: Menu[];
}

// Currently using a temporary username for test purposes. Will remove later
const initialState: MasterState = {
  isLoading: true,
  countries: [] as Country[],
  states: [] as State[],
  icons: [] as Icons[],
  userTypes: [] as UserTypes[],
  isPermissionsLoading: true,
  isRefreshTokenLoading: false,
  isError: false,
  menu: {} as Menu[],
};

const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setLoading(state, { payload: { isLoading } }: PayloadAction<any>) {
      state.isLoading = isLoading;
    },
    setPermissionsLoader(state, { payload: { isPermissionsLoading } }: PayloadAction<any>) {
      state.isPermissionsLoading = isPermissionsLoading;
    },
    setRefreshTokenLoading(state, { payload: { isRefreshTokenLoading } }: PayloadAction<any>) {
      state.isRefreshTokenLoading = isRefreshTokenLoading;
    },
    setCountry(state, { payload: { countries } }: PayloadAction<{ countries: Country[] }>) {
      state.countries = countries;
    },
    setState(state, { payload: { states } }: PayloadAction<{ states: State[] }>) {
      state.states = states;
    },
    setIcons(state, { payload: { icons } }: PayloadAction<{ icons: Icons[] }>) {
      state.icons = icons;
    },
    setUserTypes(state, { payload: { userTypes } }: PayloadAction<{ userTypes: UserTypes[] }>) {
      state.userTypes = userTypes;
    },
    setError(state, { payload: { isError } }: PayloadAction<any>) {
      state.isError = isError;
    },
    setMenu(state, { payload: { menu } }: PayloadAction<{ menu: Menu[] }>) {
      state.menu = menu;
    },
  },
});

export const {
  setLoading,
  setPermissionsLoader,
  setRefreshTokenLoading,
  setCountry,
  setState,
  setUserTypes,
  setError,
  setIcons,
  setMenu,
} = masterSlice.actions;
export default masterSlice.reducer;
