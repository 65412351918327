import { useQueries } from '@tanstack/react-query';

import {
  fetchBusinessDurationMasterData,
  fetchGeographicAreasMasterData,
  fetchTargetMarketLookup,
  fetchTypeOfCompaniesMasterData,
} from '../../../../api/services/masterData/masterData';
import { fetchPrimaryBusiness } from '../../../../api/services/partnerOnboarding/partnerOnboardingService';

export const useFetchMoreCompanyDetails = () => {
  const queries = useQueries({
    queries: [
      {
        queryKey: ['TYPE_OF_COMPANIES'],
        queryFn: () => fetchTypeOfCompaniesMasterData(),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['PRIMARY_BUSINESS'],
        queryFn: () => fetchPrimaryBusiness(),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['GEOGRAPHIC_AREAS'],
        queryFn: () => fetchGeographicAreasMasterData(),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['BUSINESS_DURATION'],
        queryFn: () => fetchBusinessDurationMasterData(),
        staleTime: 5 * 60 * 1000,
      },
      {
        queryKey: ['TARGET_MARKETS'],
        queryFn: () => fetchTargetMarketLookup(),
        staleTime: 5 * 60 * 1000,
      },
    ],
  });

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);

  const data = {
    TYPE_OF_COMPANIES: queries[0].data,
    PRIMARY_BUSINESS: queries[1].data,
    GEOGRAPHIC_AREAS: queries[2].data,
    BUSINESS_DURATION: queries[3].data,
    TARGET_MARKETS: queries[4].data,
  };

  return { data, isLoading, isError };
};
