import { convertMasterData } from './convertMasterData';
import Translations from '../../../../assets/locale/en/content.json';
import { FieldConfig } from '../../../../common/interfaces/InputFieldConfig';
import { Lookup } from '../../../../common/interfaces/Lookup';
import { Master } from '../../../../common/interfaces/Masters';

/**
Create a field config for form to render fields on UI
@parameter {TYPE_OF_COMPANIES} - List of options for TYPE_OF_COMPANIES field.
@parameter {PRIMARY_BUSINESS} - List of options for PRIMARY_BUSINESS field.
@parameter {GEOGRAPHIC_AREAS} - List of options for GEOGRAPHIC_AREAS field.
@parameter {BUSINESS_DURATION} - List of options for BUSINESS_DURATION field.
@parameter {TARGET_MARKETS} - List of options for TARGET_MARKETS field.
@returns FieldConfig[] - return field config
*/
export const GetInputFieldConfig = (
  TYPE_OF_COMPANIES: Master[] | undefined,
  PRIMARY_BUSINESS: Master[] | undefined,
  GEOGRAPHIC_AREAS: Master[] | undefined,
  BUSINESS_DURATION: Master[] | undefined,
  TARGET_MARKETS: Lookup[] | undefined,
): FieldConfig[] => {
  const fieldConfig: FieldConfig[] = [
    [
      {
        label: Translations.partnerOnboarding.typeOfCompany,
        htmlFor: 'typeOfCompany',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: convertMasterData(TYPE_OF_COMPANIES),
        placeholder: '',
        allowMultiple: false,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.primaryBusiness,
        htmlFor: 'primaryTypeOfBusiness',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: convertMasterData(PRIMARY_BUSINESS),
        placeholder: '',
        allowMultiple: false,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.geographicAreaServed,
        htmlFor: 'geographicAreaServed',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: convertMasterData(GEOGRAPHIC_AREAS),
        placeholder: '',
        allowMultiple: false,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.lengthOfBusiness,
        htmlFor: 'lengthInTimeInBusiness',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: convertMasterData(BUSINESS_DURATION),
        placeholder: '',
        allowMultiple: false,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.targetMarket,
        htmlFor: 'targetMarkets',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: TARGET_MARKETS,
        placeholder: '',
        allowMultiple: true,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.numberOfEmployees,
        htmlFor: 'numberOfEmployees',
        fieldType: 'number',
        validationRules: { required: true, maxLength: 8 },
        layoutRatio: 2,
        placeholder: Translations.partnerOnboarding.enterNumberOfEmployees,
      },
    ],
    [
      {
        label: Translations.partnerOnboarding.distributingOtherResellers,
        htmlFor: 'isSellingThroughOtherResellers',
        fieldType: 'chip',
        validationRules: { required: true },
        chipOptions: [
          { name: 'No', id: 0 },
          { name: 'Yes', id: 1 },
        ],
        placeholder: '',
        allowMultiple: false,
      },
    ],
  ];
  return fieldConfig;
};
