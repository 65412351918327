import React, { ReactNode } from 'react';

import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import SideMenu from './SideMenu';
import { TngGrid } from '../../common';

interface LayoutProps {
  children?: ReactNode;
}

const DashboardLayout: React.FC<LayoutProps> = () => {
  return (
    <TngGrid className="layout_container">
      <Navbar />
      <TngGrid className={`dashboard_container`}>
        <SideMenu />
        <Outlet />
      </TngGrid>
    </TngGrid>
  );
};

export default React.memo(DashboardLayout);
