import Translations from '../../../../../assets/locale/en/content.json';
import { ColumnDef } from '../../../../../common/interfaces/ColumnDef';

// this function is used to create column definations for financial details grid
export const getColumnHeaders = (isUserPTU: boolean): ColumnDef[] => {
  return [
    {
      name: 'productName',
      displayName: Translations.OrderSummary.product,
      sort: 'asc',
      showTooltip: true,
      colWidth: 350,
    },
    {
      name: 'itemNumber',
      displayName: Translations.OrderSummary.itemNumber,
      colWidth: 220,
    },
    {
      name: 'quantity',
      displayName: Translations.OrderSummary.quantity,
      colWidth: 120,
    },
    {
      name: 'unitPrice',
      displayName: Translations.OrderSummary.unitPrice,
      colWidth: 140,
      hideColumn: isUserPTU,
    },
    {
      name: 'totalPrice',
      displayName: Translations.OrderSummary.totalPrice,
      hideColumn: isUserPTU,
    },
    { name: 'startDate', displayName: Translations.OrderSummary.startDate },
    { name: 'endDate', displayName: Translations.OrderSummary.endDate },
    {
      name: 'salesLevelAgreement',
      displayName: Translations.OrderSummary.slo,
      sortable: false,
      colWidth: 95,
    },
  ];
};
