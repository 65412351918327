import { Master } from '../../../../common/interfaces/Masters';

export const convertMasterData = (masterData: Master[] | undefined) => {
  const data =
    masterData &&
    masterData.map((val, index) => {
      return { name: val.value, id: index };
    });

  return data;
};
