// Define Grid Content Action types
export enum GridActionEnum {
  SET_FILTERS = 'SET_FILTERS',
  SET_SEARCH = 'SET_SEARCH',
  SET_SORTING = 'SET_SORTING',
  SET_SEARCH_BY_OF_SEARCH = 'SET_SEARCH_BY_OF_SEARCH',
  SET_SEARCH_VALUE_OF_SEARCH = 'SET_SEARCH_VALUE_OF_SEARCH',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER = 'SET_PAGE_SIZE_WITH_DEFAULT_PAGE_NUMBER',
  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
  SET_TOTAL_PAGE = 'SET_TOTAL_PAGE',
  SET_TOTAL_RECORD = 'SET_TOTAL_RECORD',
  SET_GRID_DATA = 'SET_GRID_DATA',
  SET_GRID_MAPPED_DATA = 'SET_GRID_MAPPED_DATA',
  SET_LOADING = 'SET_LOADING',
  SET_GRID_API = 'SET_GRID_API',
  SET_LOADER = 'SET_LOADER',
  SET_IS_FILTER_APPLIED = 'SET_IS_FILTER_APPLIED',
  SET_IS_FILTER_OPEN = 'SET_IS_FILTER_OPEN',
  SET_IS_SEARCH_APPLIED = 'SET_IS_SEARCH_APPLIED',
  SET_IS_SORTING_APPLIED = 'SET_IS_SORTING_APPLIED',
  SET_IS_DEFAULT_VALUE_APPLIED = 'SET_IS_DEFAULT_VALUE_APPLIED',
}
