import { useCallback, useState } from 'react';

import { recoverPasswordService } from '../../api/services/auth/authService';
import Translations from '../../assets/locale/en/content.json';
import { PasswordRecoveryParams } from '../interfaces/UserAccount';

const usePasswordRecovery = () => {
  const [loading, setLoading] = useState(false);
  // Define the function to recover the password
  const recoverPassword = useCallback(async (resetPasswordBody: PasswordRecoveryParams) => {
    setLoading(true);
    try {
      const response = await recoverPasswordService(resetPasswordBody);

      return response;
    } catch (error: any) {
      console.error(Translations.ResetPassword.recoveryError, error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return { recoverPassword, loading };
};

export default usePasswordRecovery;
