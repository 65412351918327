// src/common/hooks/useUserLogin.ts
import { useState, useCallback } from 'react';

import { useDispatch } from 'react-redux';

import {
  authenticateUser,
  exchangeAuthorizationCode,
  getAuthorizationCode,
  initiateFlow,
} from '../../api/services/auth/authService';
import Translations from '../../assets/locale/en/content.json';
import { login } from '../../store/auth/authSlice';
import { AuthTokens, UserData } from '../interfaces/UserAccount';

export const useUserLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const loginUser = useCallback(
    async (data: UserData, resumeUrl?: string): Promise<AuthTokens | null> => {
      setLoading(true);
      setError(null);

      try {
        let finalResumeUrl = resumeUrl;

        if (!finalResumeUrl) {
          // If resumeUrl is not provided, initiate flow and authenticate user
          const flowId = await initiateFlow();
          finalResumeUrl = await authenticateUser({
            flowId,
            userCredentials: data,
          });
        }

        // Get authorization code using the final resume URL
        const authCode = await getAuthorizationCode(finalResumeUrl);

        // Exchange authorization code for tokens
        const tokens = await exchangeAuthorizationCode(authCode);

        // Dispatch login action to Redux store
        dispatch(login({ token: tokens.accessToken, refreshToken: tokens.refreshToken }));

        setLoading(false);
        return tokens;
      } catch (error: any) {
        // Handle/display error according to the error code
        setError(Translations.Login.loginClientError);
        setLoading(false);
        throw Error(Translations.Login.loginClientError);
      }
    },
    [dispatch],
  );

  return { loginUser, loading, error };
};
