import React, { useEffect, useState } from 'react';

import Translations from '../../../assets/locale/en/content.json';
import { useOrderStatus } from '../../../common/hooks/useOrderStatus';
import { StatusColors } from '../../../common/interfaces/StatusColor';
import { TngBox, TngGrid } from '../../../components/common';
import { CreateStatusColorObj } from '../../../utils/createStatusColorObj';
import { HeaderDetails } from '../modals/headerDetails';

interface FieldHeaderProps {
  headerDetails: HeaderDetails | undefined;
}

// Define the type for the status colors mapping

const FieldHeader: React.FC<FieldHeaderProps> = ({ headerDetails }) => {
  const [statusColors, setStatusColors] = useState<StatusColors>({} as StatusColors);
  const { data: orderStatuses = [] } = useOrderStatus();

  useEffect(() => {
    // Function to populate status colors from API response
    if (orderStatuses && orderStatuses.length > 0) {
      const colors = CreateStatusColorObj(orderStatuses);
      setStatusColors(colors);
    }
  }, [orderStatuses]);

  const borderColor = (status: string) => {
    const color = `#${statusColors[status as keyof typeof statusColors]}`;
    return color;
  };
  return (
    <TngBox className="fields_header">
      <TngGrid className="field_wrapper">
        <TngGrid className="field_name">{Translations.OrderSummary.quoteId}</TngGrid>
        <TngGrid className="info_field_value">
          <TngGrid className="field_value">{headerDetails?.quoteId}</TngGrid>
          <TngGrid
            className="field_status"
            sx={{ border: `1px solid ${borderColor(`${headerDetails?.orderStatus}`)}` }}
          >
            {headerDetails?.orderStatus}
          </TngGrid>
        </TngGrid>
      </TngGrid>
    </TngBox>
  );
};

export default FieldHeader;
