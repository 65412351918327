import React, { useState } from 'react';

import Translations from '../../../../assets/locale/en/content.json';
import { Customer } from '../../../../common/interfaces/customers/customers';
import { ErrorProps } from '../../../../common/interfaces/SearchDropdown';
import { TngGrid } from '../../../../components/common';
import TngMultiSelectSearchDropdown from '../../../../components/common/TngMultiSelectSearchDropdown';
import { useCustomers } from '../../hooks/useCustomers';
import { OrderHistoryFilter } from '../../modals/OrderHistoryFilter';

interface CustomerDropdownProps {
  filterObj: OrderHistoryFilter;
  setFiltersObj: React.Dispatch<React.SetStateAction<OrderHistoryFilter>>;
}

const CustomerDropdown: React.FC<CustomerDropdownProps> = ({ filterObj, setFiltersObj }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [error, setError] = useState<ErrorProps>({
    isError: false,
    message: '',
  });
  const { getCustomers } = useCustomers();

  // filtered out data/options according to selected user type
  const filterOptions: any = () => {
    return customers.map((cus: Customer) => {
      return { name: cus.name, id: cus.id };
    });
  };

  const handleSearchButtonClick = async () => {
    if (searchValue.trim().length >= 3) {
      const customers = await getCustomers({ isSoldThroughCustomer: false }, searchValue);

      customers.length === 0
        ? setError({ isError: true, message: Translations.filterSection.errorMessage })
        : setError({ isError: false });
      setCustomers(customers);
    } else {
      setError({ isError: true, message: Translations.filterSection.minSearchValueText });
    }
  };

  return (
    <TngGrid container marginTop="20px">
      <TngMultiSelectSearchDropdown
        dropdownLabel={Translations.filterSection.customerName}
        options={filterOptions()}
        selectedOptions={filterObj.customerIds}
        placeholderText={Translations.filterSection.typeCustomerNamePlaceholder}
        handleSearchButtonClick={handleSearchButtonClick}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setOptionValue={(newSelectedCustomerValues) => {
          const selectedIds = newSelectedCustomerValues.map((customer) => customer.id);
          setFiltersObj({
            ...filterObj,
            customerIds: newSelectedCustomerValues
              .filter((cus) => selectedIds.includes(cus.id))
              .map((cus) => cus.id),
            customerValue: newSelectedCustomerValues
              .filter((cus) => selectedIds.includes(cus.id))
              .map((cus) => ({ id: cus.id, name: cus.name })),
          });
        }}
        optionValue={filterObj.customerValue}
        error={error}
        setError={setError}
      />
    </TngGrid>
  );
};

export default CustomerDropdown;
