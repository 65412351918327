import { useCallback } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { saveUserProfile } from '../../../../../api/services/user/userService';
import Translations from '../../../../../assets/locale/en/content.json';
import { SaveUserProfile } from '../../../../../common/interfaces/user/UserProfile';
import { useToast } from '../../../../../components/Toasts/ToastContext';

export const useSaveUserProfile = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ user, contactId }: { user: SaveUserProfile; contactId: string }) => {
      return await saveUserProfile(contactId, user);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      showToast(Translations.common.formSuccesMessage, { type: 'success' });
    },
  });

  const saveUser = useCallback(
    async (user: SaveUserProfile, contactId: string) => {
      try {
        await mutation.mutateAsync({ user, contactId });
      } catch (err: any) {
        showToast(Translations.common.formErrorMessage, { type: 'error' });
        throw new Error(err.message);
      }
    },
    [mutation, showToast],
  );

  return { saveUser, loading: mutation.status === 'pending' };
};
