import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import MoreCompanyDetails from './CompanyDetails';
import CompanyInformation from './CompanyInformation';
import { usePartnerOnboardingDispatch, usePartnerOnboardingState } from './context/ContextProvider';
import { ContextActionEnum } from './enums/ContextActionEnums';
import { mapLeadDataToContext } from './helper/mapLeadDataToContext';
import ProductsYouWantToOffer from './ProductsYouWantToOffer';
import RevenueTarget from './RevenueTarget';
import TermsAndConditions from './TermsAndConditions';
import Translations from '../../assets/locale/en/content.json';
import { useGetPartnerOnboarding } from '../../common/hooks/useGetPartnerOnboarding';
import { useLeadStatus } from '../../common/hooks/useLeadStatus';
import { TngGrid, TngLoader } from '../../components/common';
import TngBottomPanel, { TngBottomPanelRef } from '../../components/common/TngBottomPanel';
import VerticalStepper from '../../components/common/TngStepper';
import { RootState } from '../../store';
import { setPartnerApplication } from '../../store/partner/partnerReducer';

const PartnerOnboardingSteps: string[] = [
  Translations.partnerOnboarding.companyInformation,
  Translations.partnerOnboarding.moreCompanyDetails,
  Translations.partnerOnboarding.revenueTarget,
  Translations.partnerOnboarding.productOffers,
  Translations.partnerOnboarding.termsAndConditions,
];

const PartnerOnboardingWorkflow = () => {
  const leadId = useSelector((state: RootState) => state.partner.leadId);
  const dispatch = useDispatch();
  const partnerOnboardingDispatch = usePartnerOnboardingDispatch();
  const [isGetLeadAPICallable, setIsLeadAPICallable] = useState<boolean>(false);
  const { stepsCompleted, prevButtonProps, nextButtonProps, activeStep } =
    usePartnerOnboardingState();

  // call leadStatus API only when we don't have any leadId on redux store
  const { data: leadStatus, isLoading: leadStatusLoading } = useLeadStatus(leadId);
  // call leadDetails API only when we have leadId in our redux store
  const { data: leadData, isLoading } = useGetPartnerOnboarding(isGetLeadAPICallable, leadId);

  useEffect(() => {
    if (leadStatus && !leadStatusLoading) {
      dispatch(setPartnerApplication({ status: leadStatus.status, leadId: leadStatus.id }));
      if (leadStatus.id) {
        setIsLeadAPICallable(true);
      }
    }
  }, [dispatch, leadStatus, leadStatusLoading]);

  // after leadData is fetched then update the context
  useEffect(() => {
    if (leadData && !isLoading) {
      const {
        companyInformation,
        moreCompanyDetails,
        revenueTargetState,
        productsOffer,
        stepsCompleted,
      } = mapLeadDataToContext(leadData);

      partnerOnboardingDispatch({
        type: ContextActionEnum.UPDATE_STEP_COMPLETED,
        payload: stepsCompleted,
      });

      partnerOnboardingDispatch({
        type: ContextActionEnum.UPDATE_ACTIVE_STEP,
        payload: stepsCompleted,
      });

      partnerOnboardingDispatch({
        type: ContextActionEnum.SET_COMPANY_INFORMATION,
        payload: companyInformation,
      });
      partnerOnboardingDispatch({
        type: ContextActionEnum.SET_MORE_COMPANY_DETAILS,
        payload: moreCompanyDetails,
      });
      partnerOnboardingDispatch({
        type: ContextActionEnum.SET_REVENUE_TARGET,
        payload: revenueTargetState,
      });
      partnerOnboardingDispatch({
        type: ContextActionEnum.SET_PRODUCTS_OFFER,
        payload: productsOffer,
      });
    }
  }, [isLoading, leadData, partnerOnboardingDispatch]);

  const stepRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  const bottomPanelRef = useRef<TngBottomPanelRef>(null);

  const PartnerOnboardingComponents: JSX.Element[] = [
    <CompanyInformation ref={stepRefs[0]} key={1} bottomPanelRef={bottomPanelRef} />,
    <MoreCompanyDetails ref={stepRefs[1]} key={2} bottomPanelRef={bottomPanelRef} />,
    <RevenueTarget ref={stepRefs[2]} key={3} bottomPanelRef={bottomPanelRef} />,
    <ProductsYouWantToOffer ref={stepRefs[3]} key={4} bottomPanelRef={bottomPanelRef} />,
    <TermsAndConditions ref={stepRefs[4]} key={5} bottomPanelRef={bottomPanelRef} />,
  ];

  const setActiveStep = (stepNumber: number) => {
    partnerOnboardingDispatch({
      type: ContextActionEnum.UPDATE_ACTIVE_STEP,
      payload: stepNumber,
    });
  };

  const setCompletedStep = (stepNumber: number) => {
    partnerOnboardingDispatch({
      type: ContextActionEnum.UPDATE_STEP_COMPLETED,
      payload: stepNumber,
    });
  };

  useEffect(() => {
    switch (activeStep) {
      case 0:
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.common.close },
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: `${Translations.common.next} ${Translations.partnerOnboarding.moreCompanyDetails}`,
            disabled: true,
          },
        });
        break;
      case 1:
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.common.previous },
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: `${Translations.common.next} ${Translations.partnerOnboarding.revenueTarget}`,
          },
        });
        break;
      case 2:
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.common.previous },
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: `${Translations.common.next} ${Translations.partnerOnboarding.productOffers}`,
          },
        });
        break;
      case 3:
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.common.previous },
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: `${Translations.common.next} ${Translations.partnerOnboarding.termsAndConditions}`,
          },
        });
        break;
      case 4:
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_PREV_BUTTON_PROPS,
          payload: { value: Translations.common.previous },
        });
        partnerOnboardingDispatch({
          type: ContextActionEnum.SET_NEXT_BUTTON_PROPS,
          payload: {
            value: Translations.partnerOnboarding.submitPartnerApplication,
          },
        });
        break;
      default:
        break;
    }
  }, [activeStep, partnerOnboardingDispatch]); // Added partnerOnboardingDispatch to the dependency array

  const handleNextButton = () => {
    if (
      stepRefs[activeStep].current &&
      typeof stepRefs[activeStep].current.submitForm === 'function'
    ) {
      stepRefs[activeStep].current.submitForm();
    }
  };

  return (
    <TngGrid className="partner_onboarding_container" data-testid="PartnerOnboardingWorkflow">
      {isLoading || leadStatusLoading ? (
        <TngLoader />
      ) : (
        <>
          <TngGrid className="stepper_panel_container">
            <VerticalStepper
              activeStep={activeStep}
              orientation="vertical"
              stepComponents={PartnerOnboardingComponents}
              steps={PartnerOnboardingSteps}
              stepperHeading={Translations.partnerOnboarding.becomeTungstenAutomationPartner}
              setActiveStep={setActiveStep}
              completedStep={stepsCompleted}
              setCompletedStep={setCompletedStep}
            />
          </TngGrid>
          <TngGrid className="bottom_panel_container">
            <TngBottomPanel
              ref={bottomPanelRef}
              prevButtonProps={prevButtonProps}
              nextButtonProps={nextButtonProps}
              setConfigStep={setActiveStep}
              currentStep={activeStep}
              handleNextButton={handleNextButton}
              navigateToRoute="/"
            />
          </TngGrid>
        </>
      )}
    </TngGrid>
  );
};

export default PartnerOnboardingWorkflow;
